import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SearchProductPipe } from "./search-product.pipe";
import { ProductInCartPipe } from "./product-in-cart.pipe";
import { QuantityDetailProductPipe } from "./quantity-detail-product.pipe";
import { DateFormatPipe } from "./date-format.pipe";
import { GroupByPipe } from "./group-by.pipe";
import { ValidateSimbolicTicketsPipe } from "./validate-simbolic-tickets.pipe";
import { ArraySortPipe } from "./sort.pipe";

@NgModule({
  declarations: [
    SearchProductPipe,
    ProductInCartPipe,
    QuantityDetailProductPipe,
    DateFormatPipe,
    GroupByPipe,
    ValidateSimbolicTicketsPipe,
    ArraySortPipe,
  ],
  imports: [CommonModule],
  exports: [SearchProductPipe, ProductInCartPipe, QuantityDetailProductPipe, DateFormatPipe, GroupByPipe, ValidateSimbolicTicketsPipe, ArraySortPipe],
})
export class PipesModule {}
