import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card-list-order',
  templateUrl: './card-list-order.component.html',
  styleUrls: ['./card-list-order.component.scss'],
})
export class CardListOrderComponent implements OnInit {
  @Input() listOrders:any = [];
  @Output() infiniteScroll = new EventEmitter();
  @Output() selectedOrder = new EventEmitter();
  constructor() { }

  ngOnInit() {}

  loadData(e:any){
    this.infiniteScroll.emit(e);
  }

  detailOrder(order_id:number){
    this.selectedOrder.emit(order_id);
  }
}
