import { Component, OnInit } from "@angular/core";
import { NavParams } from "@ionic/angular";
import { DomSanitizer, SafeStyle } from "@angular/platform-browser";
import { CityService } from "../../services/city.service";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";

@Component({
  selector: "page-end-version",
  templateUrl: "end-version.html",
  styleUrls: ["./end-version.scss"],
})
export class EndVersionPage implements OnInit {
  backgroundImg: SafeStyle;
  textEndVersion: string;
  urlEndVersion: string;
  constructor(public navParams: NavParams,
              private sanitizer: DomSanitizer,
              public cityProvider: CityService,
              private iab: InAppBrowser) {
    this.backgroundImg = this.sanitizer.bypassSecurityTrustStyle("url(" + navParams.get("image_end_version") + ")");
    this.textEndVersion = navParams.get("text_end_version");
    this.urlEndVersion = navParams.get("link_end_version");
  }

  ngOnInit() {}

  openLink() {
    this.iab.create(this.urlEndVersion, "_system");
  }
}
