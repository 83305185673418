import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController } from '@ionic/angular';
import { UtilsService } from './utils.service';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { forkJoin } from 'rxjs';
import { ConnectionService } from './connection.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  allProducts: any = [];
  allClients: any = [];
  allOrders: any = [];
  productsCart: any = [];
  _priceDomicile: any = 0;
  paymentTypes: any = [];
  orderTypes: any = [];
  _subtotal: any = 0;
  _taxes: any = 0;
  _total: any = 0;
  _discount: any = 0;
  _discountDelivery: any = 0;
  _statusMinimumOrderPrice: boolean = false;
  _minimumOrderPrice: any = 0;
  _activeDiscount: boolean = false;
  _discountsApplied: any = [];
  _listShowToast: any = [];
  priceDomicileDefault = 0;
  domicile_by_coverage: boolean = false;
  ordersSync: any = [];
  statusSyncOrders: boolean = false;
  lastDateSync: string;
  onSyncOrders: Subject<any>;
  onUserChanged: Subject<any>;
  onOrdersChanged: BehaviorSubject<any>;
  userFactor: any = 0;
  userTaxRate: any = 0;
  _infoUserCart: any;
  bo_price_list_id: any;
  bo_tax_exempt: any;
  order_type_id: any;
  percentage_shipping_price:number = 0;
  current_user_id:any = null;
  constructor(
    private storage: StorageService,
    public api: ApiService,
    public alertController: AlertController,
    public translateService: TranslateService,
    private utilsService: UtilsService,
    private userService: UserService,
    private connectionService: ConnectionService
  ) {
    this.lastDateSync = '2021-07-06 22:04:16';
    this.onSyncOrders = new Subject();
    this.onUserChanged = new Subject();
    this.onOrdersChanged = new BehaviorSubject([]);
  }

  filterProducts(searchTerm: string) {
    if (searchTerm == '' || searchTerm.length < 2) {
      return this.allProducts.slice(0, 100);
    } else {
      return this.allProducts.filter((item: any) => {
        return (
          item.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
          item.plu.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 ||
          item.subcategory.name
            .toLowerCase()
            .indexOf(searchTerm.toLowerCase()) > -1
        );
      });
    }
  }

  getProductsCart() {
    this.storage.get('productsCart').then((productsCart) => {
      if (productsCart) {
        this.productsCart = productsCart;
      }
    });
  }

  priceDomicileByCoverage() {
    return new Promise((resolve, reject) => {
      this.userService
        .getlastAddressUser()
        .then((resp: any) => {
          if (resp && resp.coverage) {
            this._priceDomicile = resp.coverage.cost_delivery;
          }
          resolve(true);
        })
        .catch((error) => {
          reject(false);
          console.error('error getlastAddressUser: ', error);
        });
    });
  }

  getPriceDomicile() {
    this.storage.get('token').then((token) => {
      if (token) {
        this._priceDomicile = 0;
        this._minimumOrderPrice = 0;
        let seq = this.api.get('parameters/info', token.access_token);
        seq.subscribe(
          (res: any) => {
            if (res.status == 'success') {
              this.storage.set('parameters', res.parameters);
              this.domicile_by_coverage =
                res.parameters.cost_delivery_by_coverage;
              if (res.parameters.cost_delivery_by_coverage == 1) {
                this.priceDomicileByCoverage();
              }
              this.percentage_shipping_price = res.parameters.percentage_shipping_price;
              this.priceDomicileDefault = res.parameters.cost_delivery;
              this._minimumOrderPrice = res.parameters.minimum_order_price;
              this.calculatePrice();
            }
          },
          (err) => {
            console.error('Error provider cart getPriceDomicile', err);
          }
        );
      }
    });
  }

  getPaymentTypesDomicile(force: boolean) {
    this.storage
      .get('paymentTypes')
      .then((paymentTypes) => {
        if (!paymentTypes || !paymentTypes.length || force) {
          this.storage.get('token').then((token) => {
            if (token) {
              let seq = this.api.get(
                'cart/paymentTypes/list',
                token.access_token
              );
              seq.subscribe(
                (res: any) => {
                  this.storage.set('paymentTypes', res.paymentTypes);
                  this.paymentTypes = res.paymentTypes;
                },
                (err) => {
                  console.error('Error getPaymentTypesDomicile', err);
                }
              );
            }
          });
        } else {
          this.paymentTypes = paymentTypes;
        }
      })
      .catch((error) => {
        console.error('error storage paymentTypes: ', error);
      });
  }

  async presentVALIDATEAGE(product, quantity, origin) {
    const alert = await this.alertController.create({
      header: this.translateService.instant('TITLE_VALIDATE_AGE'),
      message: this.translateService.instant('TEXT_VALIDATE_AGE'),
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'primary',
          handler: (blah) => {},
        },
        {
          text: 'Si',
          handler: () => {
            this.addProduct(product, quantity, origin, false);
          },
        },
      ],
    });

    await alert.present();
  }

  validateItemsProduct(product, quantity, origin, valid_age?) {
    // Search by product id on Cart and sum quantity.
    let quantityCart = 0;
    if (origin != 'remove') {
      // Comportamiento diferente cuando viene de detail-product, ya que aquí se envian todas las unidades no de 1 en 1.
      if (origin != 'detail_product') {
        quantity = 1;
      }
      for (let i = 0; i < this.productsCart.length; i++) {
        let p = this.productsCart[i];
        if (p.id == product.id) {
          quantityCart += parseInt(p.quantity);
        }
      }
    }
    if (
      product.max_units_per_order &&
      quantity + quantityCart > product.max_units_per_order
    ) {
      this.utilsService.presentAlertInfo(this.translateService.instant('TITLE_MAX_UNITS_ORDER'),'',this.translateService.instant('TEXT_MAX_UNITS_ORDER'));
      return false;
    }

    if ((origin != 'detail_product' && quantity + quantityCart > product.available_units) || (origin == 'detail_product' && quantity > product.available_units)) {
      this.utilsService.presentAlertInfo(this.translateService.instant('TITLE_AVAILABLE_UNITS_ORDER'),'',this.translateService.instant('TEXT_AVAILABLE_UNITS_ORDER'));
      return false;
    }

    if (product.product_attributes_selected) {
      for (let i = 0; i < product.product_attributes_selected.length; i++) {
        let item = product.product_attributes_selected[i];
        if (quantity + quantityCart > item.available_units) {
          this.utilsService.presentAlertInfo(this.translateService.instant('TITLE_AVAILABLE_UNITS_ORDER'),'',this.translateService.instant('ATTRIBUTE_REQUIRED_ITEMS', {
            attribute: item.attribute.display_name,
            value: item.value,
          }));
          return false;
        }
      }
    }

    if (product.validate_age && !product.addCart && valid_age) {
      this.presentVALIDATEAGE(product, quantity, origin);
      return false;
    }

    return true;
  }

  deepCopy(obj) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || 'object' != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  addProduct(
    product,
    quantity,
    origin?,
    validate_age = true,
    special_price = null
  ) {
    console.log(product);
    if (this.validateItemsProduct(product, quantity, origin, validate_age)) {
      var clone = this.deepCopy(product);
      clone.addCart = true;
      clone.quantity = quantity;
      // Se agrega redondeo a dos places
      let price = product.price;
      if (special_price && special_price.percentage) {
        // Si es por percentage es precio especial para cliente
        price = this.roundingAmount(product.price * ((100 - special_price.percentage) / 100));

        clone.price = price;
        clone.price_special_client = true;

        clone = this.checkForSpecialPrice(clone, quantity);
      } else if (special_price && special_price.price) {
        // Si viene directo el price, es precio manual

        clone.price = special_price.price;
        clone.price_manual_price = true;
        clone = this.checkForSpecialPrice(clone, quantity);
      } else {
        price = this.roundingAmount(product.price * this.userFactor);
        clone.price = price;
        clone.price_factor = true;
        clone = this.checkForSpecialPrice(clone, quantity);
      }

      clone.indexCart = this.productsCart.length;
      this.productsCart.push(clone);
      this.storage.set('productsCart', this.productsCart);
      this.calculatePrice();
    }
  }

  updateQuantity(product, quantity, origin?) {
    let resultValidate = false;
    if (quantity != 0) {
      resultValidate = this.validateItemsProduct(product, quantity, origin);
      if (resultValidate) {
        this.productsCart[product.indexCart].quantity = quantity;
        this.productsCart[product.indexCart] = this.checkForSpecialPrice(
          this.productsCart[product.indexCart],
          quantity
        );
        this.storage.set('productsCart', this.productsCart);
        this.calculatePrice();
      }
    } else {
      this.deleteProduct(product);
    }
    return resultValidate;
  }

  updateProduct(product, origin?) {
    if (this.validateItemsProduct(product, product.quantity, origin)) {
      let clone = this.deepCopy(product);
      this.productsCart[product.indexCart] = clone;
      this.storage.set('productsCart', this.productsCart);
      this.calculatePrice();
    }
  }

  deleteProduct(product) {
    this.productsCart.splice(product.indexCart, 1);
    this.updateIndexProductsInCart();
    this.calculatePrice();
  }

  updateIndexProductsInCart() {
    if (this.productsCart && this.productsCart.length) {
      for (let i = 0; i < this.productsCart.length; i++) {
        this.productsCart[i].indexCart = i;
      }
      this.storage.set('productsCart', this.productsCart);
    }
  }

  deleteAllProductsCart() {
    this.productsCart = [];
    this.storage.removeItem('productsCart');
    this.calculatePrice();
  }

  updateComments(product, comments) {
    this.productsCart[product.indexCart].comments = comments;
    this.storage.set('productsCart', this.productsCart);
  }

  typeAttribute(object) {
    let attributeText = true;
    if (object && object != '') {
      let item = Object.entries(object);
      for (let i = 0; i < item.length; i++) {
        let attributes: any = item[i][1];
        for (let j = 0; j < attributes.length; j++) {
          let element = attributes[j];
          if (element.attribute.attribute_type_id != 1) {
            attributeText = false;
          }
        }
      }
    }
    return attributeText;
  }

  itemProductTour(product) {
    product.attributeText = this.typeAttribute(product.product_attributes);
    product.priceAfterFlash = 0;
    product.priceAfterDiscount = 0;
    let quantity = 0;
    let quantityGoblal = 0;
    let statusInCart = false;
    for (let i = 0; i < this.productsCart.length; i++) {
      const element = this.productsCart[i];
      if (product.id == element.id) {
        product.indexCart = i;
        quantity = element.quantity;
        quantityGoblal += quantity;
        statusInCart = true;
      }
    }

    if (statusInCart) {
      product.addCart = true;
      product.quantity = quantity;
      product.quantityGoblal = quantityGoblal;
    } else {
      product.addCart = false;
      product.quantity = 0;
      product.quantityGoblal = 0;
    }
    return product;
  }

  calculatePrice() {
    this._subtotal = 0;
    this._taxes = 0;
    this._discount = 0;
    this._total = 0;

    if (this.productsCart && this.productsCart.length) {
      for (let i = 0; i < this.productsCart.length; i++) {
        let quantity = this.productsCart[i].quantity;
        let price = this.productsCart[i].price;
        let currentDiscount = 0;
        let priceTotal = 0;
        let priceWithDiscount = 0;
        let aditionalPrice = 0;
        if (this.productsCart[i].priceAfterDiscount > 0) {
          currentDiscount =
            quantity * (price - this.productsCart[i].priceAfterDiscount);
          this._discount += currentDiscount;
          priceWithDiscount = this.productsCart[i].priceAfterDiscount;
        } else if (this.productsCart[i].priceAfterFlash > 0) {
          currentDiscount =
            quantity * (price - this.productsCart[i].priceAfterFlash);
          this._discount += currentDiscount;
          priceWithDiscount = this.productsCart[i].priceAfterFlash;
        } else {
          priceWithDiscount = price;
        }

        if (
          this.productsCart[i].product_attributes_selected &&
          this.productsCart[i].product_attributes_selected.length
        ) {
          for (
            let v = 0;
            v < this.productsCart[i].product_attributes_selected.length;
            v++
          ) {
            let itemQuantity = 1;
            const element = this.productsCart[i].product_attributes_selected[v];
            if (element.quantity) {
              itemQuantity = element.quantity;
            }
            aditionalPrice += element.price_additional * itemQuantity;
          }
        }

        priceTotal = priceWithDiscount + aditionalPrice;
        this.productsCart[i].priceTotal = priceTotal;
        this.productsCart[i].priceWithDiscount = priceWithDiscount;

        this._subtotal += quantity * priceTotal;
      }
      if(!this.bo_tax_exempt){
        this._taxes = (this._subtotal * this.userTaxRate) / 100;
      }

      // Validacion, para tomar solo los dos primero decimal places con redondeo. simil a B1
      if (this._taxes > 0) {
        this._taxes = this.roundingAmount(this._taxes);
      }

      this._total = this._subtotal + this._priceDomicile + this._taxes;

      this.storage.get('parameters').then((parameters) => {
        if (parameters) {
          if (parameters.minimum_order_price > this._subtotal) {
            this._statusMinimumOrderPrice = true;
          } else {
            this._statusMinimumOrderPrice = false;
          }
        } else {
          this._statusMinimumOrderPrice = false;
        }
      });
    }
  }

  roundingAmount(value,numberFixDecimal = 2){
    if(value){
      let validateRounding = value.toString().split(".").length > 1 ? value.toString().split(".")[1].length : 2;
      if(validateRounding > 3 && (parseInt(value.toString().split(".")[1][3]) > 5) && numberFixDecimal == 2){
        value = this.roundingAmount(value,3);
      }
      if(validateRounding === 3){
        let unitRound = value.toString().split(".")[1][2];
        if(unitRound && unitRound == '5'){
          value = parseFloat(value.toString().substring(0,5) + '6');
        }
      }
      return parseFloat((Math.round((value + Number.EPSILON) * Math.pow(10, numberFixDecimal)) / Math.pow(10, numberFixDecimal)).toFixed(numberFixDecimal));
    }
    return value;
  }

  async initOrders() {
    return Promise.all([
      this.storage.get('ordersSync'),
      this.storage.get('lastDateSync'),
    ])
      .then((results) => {
        if (results[0] && results[0].length) {
          this.ordersSync = results[0];
        }
        this.lastDateSync = results[1] || '2021-07-06 22:04:16';
      })
      .catch((error) => {
        console.error(error);
      });
  }

  arrayRequestMultipleOrders(access_token: any) {
    let requests = [];
    this.ordersSync.map((orderInfo: any) => {
      requests.push(
        this.api.post('cart/userCreateOrder', orderInfo, access_token)
      );
    });
    return requests;
  }

  syncAllOrders() {
    if (
      this.ordersSync &&
      this.ordersSync.length &&
      this.connectionService.statusConnection
    ) {
      this.storage.get('token').then((token) => {
        if (token) {
          this.statusSyncOrders = true;
          this.onOrdersChanged.next([]);
          forkJoin(
            this.arrayRequestMultipleOrders(token.access_token)
          ).subscribe(
            (results) => {
              this.statusSyncOrders = false;
              results.forEach((result, index) => {
                if (result['status'] == 'success') {
                  this.ordersSync.splice(index, 1);
                } else if(result['status'] == 'error_ebiz'){
                  this.ordersSync.splice(index, 1);
                  this.utilsService.presentToast("4000","danger","top",result['m']);
                }else{
                  this.ordersSync[index]['messageError'] = result['m'];
                }
              });
              this.storage.set('ordersSync', this.ordersSync);
              this.lastDateSync = this.utilsService.currentDate();
              this.storage.set('lastDateSync', this.lastDateSync);
              this.onOrdersChanged.next(this.ordersSync);
              this.getAllProducts();
            },
            (error) => {
              console.error('error syncAllOrders: ', error);
              this.statusSyncOrders = false;
              this.onOrdersChanged.next(this.ordersSync);
            }
          );
        }
      });
    }
  }

  createOrder(orderInfo){
    return new Promise((resolve, reject) => {
      this.storage.get('token').then((token) => {
        if (token) {
          this.utilsService.presentLoading(this.translateService.instant('all.loading'));
          this.api.post('cart/userCreateOrder', orderInfo, token.access_token).subscribe(resp=>{
            this.utilsService.dismissLoading();
            if (resp['status'] == 'success') {
              resolve(true);
              this.getAllProducts();
            }else{
              resolve(false);
              this.utilsService.presentToast("4000","danger","top",resp['m']);
            }
          },error=>{
            this.utilsService.dismissLoading();
            reject(error);
          });
        }else{
          resolve(false);
        }
      });
    });
  }

  copyProductsToOrder(products) {
    for (let i = 0; i < products.length; i++) {
      let product_attributes_selected = [];
      if (
        products[i].order_product_attributes &&
        products[i].order_product_attributes.length
      ) {
        for (let j = 0; j < products[i].order_product_attributes.length; j++) {
          let attribute = products[i].order_product_attributes[j];
          for (
            let k = 0;
            k < products[i].products.product_attributes.length;
            k++
          ) {
            let current_attribute = products[i].products.product_attributes[k];
            if (
              current_attribute.product_id == products[i].product_id &&
              current_attribute.attribute_id == attribute.attribute_id &&
              current_attribute.value == attribute.value
            ) {
              product_attributes_selected.push(current_attribute);
            }
          }
        }
        if (products[i].products.active) {
          products[i].products.product_attributes_selected =
            product_attributes_selected;
        }
      }
      this.addProduct(products[i].products, products[i].quantity);
    }
  }

  getDiscounts(access_token) {
    return this.api.get('cart/discount/list', access_token);
  }

  getGlobalInventoryForPLU(plu){

    return new Promise((resolve, reject) => {
      this.storage.get('token').then((token) => {
        if (token && this.connectionService.statusConnection) {
          const seq = this.api.get(`pos/product/globalInventory/${plu}`, token.access_token);
          seq.subscribe(
            (res: any) => {
              resolve(res);
            },
            (err) => {
              console.error('Error provider cart getGlobalInventoryForPLU', err);
              reject(err);
            }
          );
        }
      });
    });
  }

  applyDiscount(discount) {
    if (!discount.is_cumulative_another_discounts && this._activeDiscount) {
      this.utilsService.presentToast(
        4000,
        'warning',
        'top',
        this.translateService.instant('TEXT_TOAST_DISCOUNT') + discount.name
      );
    } else {
      if (this.validateDateDiscount(discount)) {
        if (this.validateDiscountRedeemed(discount.id)) {
          if (this.validateUserDiscount(discount)) {
            this._activeDiscount = true;
            let newDiscount = {
              discount_id: discount.id,
              discount_type_id: discount.discount_type_id,
            };
            this._discountsApplied.push(newDiscount);
            if (discount.price_discount) {
              this._discount += discount.price_discount;
              this._total -= discount.price_discount;
            } else {
              let currentDiscount =
                (this._total * discount.percentage_discount) / 100;
              this._discount += currentDiscount;
              this._total -= currentDiscount;
            }
            if (discount.discount_type_id === 4) {
              this.utilsService.presentToast(
                4000,
                'warning',
                'top',
                discount.message_to_user
              );
            } else {
              this.toatDiscountApply(discount.message_to_user);
            }
          }
        } else {
          this.toatDiscountApply(
            this.translateService.instant('TEXT2_TOAST_DISCOUNT')
          );
        }
      } else {
        if (discount.discount_type_id === 4) {
          this.toatDiscountApply(
            'El descuento cupón: ' + discount.name + ' no está vigente'
          );
        }
      }
    }
  }

  validateDiscountRedeemed(discount_id) {
    let status = false;
    let existsDiscount = false;
    if (this._discountsApplied.length) {
      for (let i = 0; i < this._discountsApplied.length; i++) {
        if (this._discountsApplied[i].discount_id == discount_id) {
          existsDiscount = true;
        }
      }
      if (existsDiscount) {
        status = false;
      } else {
        status = true;
      }
    } else {
      status = true;
    }
    return status;
  }

  validateUserDiscount(discount) {
    let status = true;
    if (discount.discountOrderUsers && !discount.is_multiple_redeem) {
      status = false;
    }
    return status;
  }

  validateDateDiscount(discount) {
    let status = true;
    let currentDate = new Date();
    let startDiscount = new Date(
      discount.when_start.replace(/\s+/g, 'T').concat('.000-05:00')
    );
    let finishDiscount = new Date(
      discount.when_finish.replace(/\s+/g, 'T').concat('.000-05:00')
    );
    if (
      startDiscount.getTime() < currentDate.getTime() &&
      finishDiscount.getTime() > currentDate.getTime()
    ) {
      status = true;
    } else {
      status = false;
    }
    return status;
  }

  toatDiscountApply(message) {
    let toast = {
      message: message,
    };
    this._listShowToast.push(toast);
  }

  showListToast() {
    let listShowToast = this._listShowToast;
    let t = this.utilsService;
    if (listShowToast && listShowToast.length) {
      for (let i = 0; i < listShowToast.length; i++) {
        setTimeout(
          function () {
            t.presentToast(4000, 'warning', 'top', listShowToast[i].message);
          }.bind(i, listShowToast[i], t),
          i * 4000
        );
      }
    }
  }

  getAllProducts() {
    return new Promise((resolve, reject) => {
      this.storage.get('token').then((token) => {
        if (token && this.connectionService.statusConnection) {
          let seq = this.api.get('pos/allProducts', token.access_token);
          seq.subscribe(
            (res: any) => {
              this.allProducts = res.data;
              this.storage.set('allProducts', this.allProducts);
              this.lastDateSync = this.utilsService.currentDate();
              this.storage.set('lastDateSync', this.lastDateSync);
              resolve(res);
            },
            (err) => {
              console.error('Error provider cart getAllProducts', err);
              reject(err);
            }
          );
        }
      });
    });
  }

  getAllClients() {
    return new Promise((resolve, reject) => {
      this.storage.get('token').then((token) => {
        if (token) {
          let seq = this.api.get('pos/allUsers', token.access_token);
          seq.subscribe(
            (res: any) => {
              this.allClients = res.data;
              this.storage.set('allClients', this.allClients);
              resolve(res);
            },
            (err) => {
              console.error('Error provider cart getAllClients', err);
              reject(err);
            }
          );
        }
      });
    });
  }

  getAllOrders(take: number, offset: number) {
    return new Promise((resolve, reject) => {
      this.storage.get('token').then((token) => {
        if (token) {
          let seq = this.api.get(
            `pos/allOrders/${take}/${offset}`,
            token.access_token
          );
          seq.subscribe(
            (res: any) => {
              if (!offset) {
                this.allOrders = [];
              }
              this.allOrders.push(...res.data);
              this.storage.set('allOrders', this.allOrders);
              resolve(res.data);
            },
            (err) => {
              console.error('Error provider cart allOrders', err);
              reject(err);
            }
          );
        }
      });
    });
  }

  getOrderTypes(force: boolean) {
    this.storage.get('orderTypes').then((orderTypes) => {
      if (!orderTypes || !orderTypes.length || force) {
        this.storage.get('token').then((token) => {
          if (token) {
            let seq = this.api.get('pos/orderTypes', token.access_token);
            seq.subscribe(
              (res: any) => {
                this.statusSyncOrders = false;
                this.orderTypes = res.data;
                this.storage.set('orderTypes', this.orderTypes);
              },
              (err) => {
                this.statusSyncOrders = false;
                console.error('Error getOrderTypes', err);
              }
            );
          }
        });
      } else {
        this.orderTypes = orderTypes;
      }
    });
  }

  getCustomerDocument(document) {
    this.storage.get('token').then((token) => {
      if (token) {
        const seq = this.api.get(
          `v1/ebiz/get_customer/${document}`,
          token.access_token
        );
        seq.subscribe(
          (res: any) => {
            try {
              if (res.status == true) {
                if (!res.o.payment_methods.PaymentMethodProfile) {
                  res.o.payment_methods.PaymentMethodProfile = [];
                }else if(res.o.payment_methods.PaymentMethodProfile && !res.o.payment_methods.PaymentMethodProfile.length){
                  res.o.payment_methods.PaymentMethodProfile = [
                    res.o.payment_methods.PaymentMethodProfile,
                  ];
                }
                this._infoUserCart = res;
              } else {
                this._infoUserCart = null;
                this.utilsService.presentToast(4000, 'danger', 'top', res.m);
              }
            } catch (exx){
              this._infoUserCart = null;
            }
          },
          (err) => {
            this._infoUserCart = null;
            console.error('Error provider getCustomer', err);
          }
        );
      }
    });
  }

  checkForSpecialPrice(product, quantity) {
    let bo_special_price = null;
    if (product.bo_special_prices && this.bo_price_list_id) {
      let bo_special_price_user = product.bo_special_prices.find((elem) => {
        return (
          elem.bo_price_list_id === this.bo_price_list_id &&
          quantity >= elem.quantity && elem.user_id == this.current_user_id
        );
      });

      let bo_special_price_global = product.bo_special_prices.find((elem) => {
        return (
          elem.bo_price_list_id === this.bo_price_list_id &&
          quantity >= elem.quantity && elem.user_id == null
        );
      });

      if(bo_special_price_user){
        bo_special_price = bo_special_price_user;
      }else{
        bo_special_price = bo_special_price_global;
      }

      if (bo_special_price && !product.volume_applied) {
        product.original_price = product.price;
        product.volume_applied = true;
        product.price = product.price - product.price * (bo_special_price.percentage / 100);
      } else if (!bo_special_price) {
        if (product.original_price) {
          product.price = product.original_price;
          product.volume_applied = false;
          delete product.original_price;
        }
      }
    }

    return product;
  }

  getRateList(shipTo) {
    return new Promise((resolve, reject) => {
      this.storage.get('token').then((token) => {
        if (token) {
          this.utilsService.presentLoading(this.translateService.instant('all.loading'))
          .then(() => {
            let data = {
              shipTo,
              products: this.productsCart
            }
            let seq = this.api.post('pos/rateList', data ,token.access_token);
            seq.subscribe(
              (res: any) => {
                this.utilsService.dismissLoading();
                resolve(res);
              },
              (err) => {
                this.utilsService.dismissLoading();
                console.error('Error provider getRateList', err);
                reject(err);
              }
            );
          });
        }
      });
    });
  }
}
