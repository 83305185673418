import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: "root",
})
export class SucursalService {
  constructor(public api: ApiService) {}

  listSucursals() {
    return this.api.getFree("listSucursals");
  }

  updateSucursalUser(sucursal_id, token) {
    let info = {
      sucursal_id: sucursal_id,
    };
    return this.api.post("user/updateSucursalUser", info, token);
  }
}
