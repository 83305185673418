import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-total-summary',
  templateUrl: './total-summary.component.html',
  styleUrls: ['./total-summary.component.scss'],
})
export class TotalSummaryComponent implements OnInit {
  @Input() title = '';
  @Input() pco_price = 0;
  @Input() pco_option = 0;
  @Input() subtotal = 0;
  @Input() priceDomicile = 0;
  @Input() discountDelivery = 0;
  @Input() discount = 0;
  @Input() total = 0;
  @Input() origin = 'others';
  @Input() tax = 0;
  @Input() useAccountBalance = false;
  @Input() accountBalance = 0;
  @Input() payment_term = '';
  @Output() infoDiscount = new EventEmitter();
  @Output() useAccountBalanceEvent = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  emitterEvent(action) {
    if (action == 'discountDelivery' || action == 'discountOrder') {
      this.infoDiscount.emit(action);
    }
  }

  emitterEventUseAccountBalance() {
    this.useAccountBalanceEvent.emit(this.useAccountBalance);
  }

  calculateTotalWithBalance() {
    let accountBalance = Math.abs(this.accountBalance);
    return this.total - accountBalance < 0 ? 0 : this.total - accountBalance;
  }
}
