import { Component, OnInit, Input  } from '@angular/core';
import { NavParams, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { PhotoViewer } from "@ionic-native/photo-viewer/ngx";
import { StreamingMedia, StreamingVideoOptions } from "@ionic-native/streaming-media/ngx";

import { CartService } from "../../services/cart.service";
import { CityService } from "../../services/city.service";
import { UtilsService } from "../../services/utils.service";

@Component({
  selector: "page-detail-product",
  templateUrl: "detail-product.html",
  styleUrls: ["./detail-product.scss"],
})
export class DetailProductPage implements OnInit {
  product: any;
  origin: any;
  attributes: any = [];
  valueControl: any = [];
  valueControlCheckbox: any = [];
  comments: any;
  priceBruto: number = 0;
  priceTotal: number = 0;
  quantity: number = 1;
  propertyButtonAdd: any = { id: 0, name: "", color: "" };
  color: any;
  productQuickView: any;

  constructor(
    public navParams: NavParams,
    public cartProvider: CartService,
    public cityProvider: CityService,
    public utilsService: UtilsService,
    public modalCtrl: ModalController,
    private photoViewer: PhotoViewer,
    private translate: TranslateService,
    private streamingMedia: StreamingMedia,
  ) {
    this.product = this.cartProvider.deepCopy(navParams.get("product"));
    this.origin = navParams.get("origin") || "";

    if (this.product.priceAfterFlash) {
      this.priceBruto = this.product.priceAfterFlash;
    } else if (this.product.priceAfterDiscount) {
      this.priceBruto = this.product.priceAfterDiscount;
    } else {
      this.priceBruto = this.product.price;
    }
    if (this.product.attributeText || this.origin == "cart") {
      this.comments = this.product.comments;
    }
    if ((this.product.addCart && this.product.attributeText) || this.origin == "cart") {
      this.quantity = this.product.quantity;
    }
  }

  ngOnInit() {
    if (this.origin != "orderDetail") {
      this.assingAttributes();
    }

  }

  closePicker() {
    this.calculatePrice();
  }

  assingAttributes() {
    if (this.product.product_attributes) {
      let currentArray = Object.entries(this.product.product_attributes);
      this.attributes = currentArray;
      for (let i = 0; i < this.attributes.length; i++) {
        let currentValueControl = null;
        let c_attributes = this.attributes[i][1];
        let currentCheckbox = [];
        for (let j = 0; j < c_attributes.length; j++) {
          let element = c_attributes[j];
          let currentValueCheckbox = {
            id: element.id,
            status: false,
            quantity: 1,
          };

          if (!this.product.attributeText && this.origin == "cart") {
            let searchAttribute = this.product.product_attributes_selected.find((item) => item.id == element.id);
            if (searchAttribute) {
              if (element.attribute.attribute_type_id == 2) {
                currentValueControl = searchAttribute.id;
              } else if (element.attribute.attribute_type_id == 3) {
                currentValueCheckbox = {
                  id: searchAttribute.id,
                  status: true,
                  quantity: searchAttribute.quantity,
                };
                currentCheckbox.push(currentValueCheckbox);
              } else if (element.attribute.attribute_type_id == 4) {
                currentValueControl = String(searchAttribute.id);
                this.color = searchAttribute.value;
              }
            } else {
              if (element.attribute.attribute_type_id == 3) {
                currentCheckbox.push(currentValueCheckbox);
              }
              if (element.attribute.attribute_type_id == 4) {
                currentValueControl = String(element.id);
                this.color = element.value;
              }
            }
          } else {
            if (element.attribute.attribute_type_id == 3) {
              currentCheckbox.push(currentValueCheckbox);
            }
            if (element.attribute.attribute_type_id == 4) {
              currentValueControl = String(element.id);
              this.color = element.value;
            }
          }
        }
        this.valueControlCheckbox[i] = currentCheckbox;
        this.valueControl.push(currentValueControl);
        if (!this.product.attributeText && this.origin == "cart" && currentCheckbox.length) {
          this.updateCheckedOptions(i, true);
        }
      }
      this.assignPropertyButton();
    }
  }

  cancel() {
    this.modalCtrl.dismiss();
  }

  addProductCart() {
    this.quantity += 1;
    this.assignPropertyButton();
  }

  removeProductCart() {
    if (this.quantity > 0) {
      if (this.quantity == 1 && !this.product.attributeText) {
        return;
      }
      this.quantity -= 1;
    }
    this.assignPropertyButton();
  }

  removeMoreOne(indexCheck, indexAttri) {
    if (this.valueControlCheckbox[indexCheck][indexAttri].quantity > 1) {
      this.valueControlCheckbox[indexCheck][indexAttri].quantity -= 1;
    }
    this.calculatePrice();
  }

  addMoreOne(indexCheck, indexAttri) {
    this.valueControlCheckbox[indexCheck][indexAttri].quantity += 1;
    this.calculatePrice();
  }

  previewImage(myImage) {
    console.log(myImage);
    this.photoViewer.show(myImage.src);
  }

  
  updateCheckedOptions(index, init?) {
    this.valueControl[index] = this.valueControlCheckbox[index];
    if (!init) {
      this.calculatePrice();
    }
  }

  updateControlRadio(event, index) {
    this.valueControl[index] = event.detail.value;
    this.calculatePrice();
  }

  inputCommentsChange() {
    this.product.comments = this.comments;
    if (this.product.addCart && this.product.attributeText) {
      this.cartProvider.updateComments(this.product, this.comments);
    }
  }

  calculatePrice() {
    this.priceTotal = 0;
    let chargeAditional = 0;
    let product_attributes_selected = [];
    if (this.valueControl && this.valueControl.length) {
      for (let i = 0; i < this.valueControl.length; i++) {
        let element = this.valueControl[i];
        if (element && !Array.isArray(element)) {
          let currentAttribute = this.attributes[i][1].find((item) => item.id == element);
          if (currentAttribute && currentAttribute.attribute.attribute_type_id == 2 && currentAttribute.price_additional > 0) {
            chargeAditional += currentAttribute.price_additional;
          } else if (currentAttribute && currentAttribute.attribute.attribute_type_id == 4) {
            currentAttribute.value = this.color;
          }
          product_attributes_selected.push(currentAttribute);
        } else if (element && Array.isArray(element)) {
          for (let v = 0; v < element.length; v++) {
            const item = element[v];
            if (item.status) {
              let currentAttributeChecbox = this.attributes[i][1].find((search) => search.id == item.id);
              if (
                currentAttributeChecbox &&
                currentAttributeChecbox.attribute.attribute_type_id == 3 &&
                currentAttributeChecbox.price_additional > 0
              ) {
                chargeAditional += currentAttributeChecbox.price_additional * item.quantity;
              }
              currentAttributeChecbox.quantity = item.quantity;
              product_attributes_selected.push(currentAttributeChecbox);
            }
          }
        }
      }
    }
    this.priceTotal = (this.priceBruto + chargeAditional) * this.quantity;
    this.product.priceTotal = this.priceTotal;
    this.product.product_attributes_selected = product_attributes_selected;
  }

  assignPropertyButton() {
    if (this.product.addCart && this.quantity > 0 && this.quantity != this.product.quantity && this.product.attributeText) {
      this.propertyButtonAdd.id = 1;
      this.propertyButtonAdd.name = this.translate.instant("BUTTONUPDATE");
      this.propertyButtonAdd.color = "primary";
    } else if (this.product.addCart && this.quantity == this.product.quantity && this.product.attributeText) {
      this.propertyButtonAdd.id = 2;
      this.propertyButtonAdd.name = this.translate.instant("BUTTONINCART");
      this.propertyButtonAdd.color = "primary";
    } else if (this.quantity == 0 && this.product.attributeText) {
      this.propertyButtonAdd.id = 3;
      this.propertyButtonAdd.name = this.translate.instant("BUTTONDELETE");
      this.propertyButtonAdd.color = "danger";
    } else if (!this.product.attributeText && this.origin == "cart") {
      this.propertyButtonAdd.id = 5;
      this.propertyButtonAdd.name = this.translate.instant("BUTTONUPDATE");
      this.propertyButtonAdd.color = "primary";
    } else {
      this.propertyButtonAdd.id = 4;
      this.propertyButtonAdd.name = this.translate.instant("BUTTONADD");
      this.propertyButtonAdd.color = "primary";
    }
    this.calculatePrice();
  }

  validateAttributes() {
    let status = true;
    for (let i = 0; i < this.attributes.length; i++) {
      let attribute = this.attributes[i][1][0].attribute;
      if (attribute.required && !this.valueControl[i]) {
        this.utilsService.presentToast(
          3000,
          "warning",
          "top",
          this.translate.instant("ATTRIBUTE_REQUIRED", {
            attribute: attribute.display_name,
          })
        );
        status = false;
        break;
      }
    }
    return status;
  }

  productChange(property_id) {
    if (this.validateAttributes()) {
      if (property_id == 1) {
        this.cartProvider.updateQuantity(this.product, this.quantity, "detail_product");
        this.cancel();
      } else if (property_id == 2) {
        this.cancel();
      } else if (property_id == 3) {
        this.cartProvider.deleteProduct(this.product);
        this.cancel();
      } else if (property_id == 4) {
        this.cartProvider.addProduct(this.product, this.quantity, "detail_product");
        this.cancel();
      } else if (property_id == 5) {
        this.product.quantity = this.quantity;
        this.cartProvider.updateProduct(this.product, "detail_product");
        this.cancel();
      }
    }
  }

  playVideo(url_product) {
    let options: StreamingVideoOptions = {
      successCallback: () => {
        console.log("Video played");
      },
      errorCallback: (e) => {
        console.log("Error streaming");
      },
      // orientation: "landscape",
      shouldAutoClose: true,
      controls: true,
    };
    this.streamingMedia.playVideo(url_product, options);
  }

  itsImage(item) {
    if (item.split(".")[1] != "mp4") {
      return true;
    }
    return;
  }
}
