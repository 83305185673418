import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderDetail } from 'src/app/interfaces/order-detail';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Platform } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from 'src/app/services/cart.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-card-detail-order',
  templateUrl: './card-detail-order.component.html',
  styleUrls: ['./card-detail-order.component.scss'],
})
export class CardDetailOrderComponent implements OnInit {
  @Input() currentOrder: OrderDetail;
  @Input() orderSelected: any;
  @Input() origin: string;
  @Output() deleteOrder = new EventEmitter();
  imagenPdfIgt: any;
  imagenPdfLogo: any;
  pdfObj = null;
  cliente: any;
  user: any;
  orderTypes: any;
  infoHost: any;
  constructor(
    public file: File,
    private fileOpener: FileOpener,
    public cartService: CartService,
    private plt: Platform,
    public utilsService: UtilsService,
    public translateService: TranslateService,
    private userService: UserService,
    private storage: StorageService,
  ) {}

  ngOnInit() {
    this.userService.getInfoUser().then((repuesta) => {
      this.user = repuesta;
    });
    this.storage.get('infoHost').then((infoHost) => {
      this.infoHost = infoHost;
    });
  }

  emitDeleteOrder(){
    this.deleteOrder.emit(this.orderSelected.data.id);
  }

  createPdfOrderSale(order: any) {
    let fechaActual = new Date();
    let dia =
      fechaActual.getDate() < 9
        ? '0' + fechaActual.getDate()
        : '' + fechaActual.getDate();
    let mes =
      fechaActual.getMonth() < 9
        ? '0' + fechaActual.getMonth()
        : fechaActual.getMonth();
    var docDefinition = {
      content: [],
      info: {
        title: 'Sales Order',
      },
      pageMargins: [40, 130, 40, 90],
      header: this.getHeader(order),
      styles: {
        nombreCliente: {
          fontSize: 15,
          bold: true,
        },
        fechaCabecera: {
          fontSize: 9,
          bold: true,
        },
        numeroPaquetes: {
          fontSize: 32,
          bold: true,
        },
        codigoPedido: {
          fontSize: 10,
          bold: false,
        },
        text: {
          fontSize: 8,
          bold: true,
        },
        subheader: {
          fontSize: 14,
          bold: true,
          markerColor: 'black',
        },
        story: {
          italic: true,
          alignment: 'center',
          width: '50%',
        },
        tableExample: {
          margin: [0, 10, 0, 15],
        },
        totalSumary: {
          margin: [310, 10, 0, 0],
        },
        total: {
          margin: [310, 0, 0, 15],
        },
      },
    };

    //Please do not change the order of the methods
    //this.getHeader(order,docDefinition);
    this.getInformationTopPanel(order, docDefinition);
    this.getPanelsInformation(order, docDefinition);
    this.getTableDescriptionClient(order, docDefinition);
    this.getTableProducts(order, docDefinition);
    this.getTotalSumary(order, docDefinition);
    this.getPanelPolicy(docDefinition);

    this.pdfObj = pdfMake.createPdf(docDefinition);
  }

  createPdfPickTicket(order: any) {
    let fechaActual = new Date();
    let dia =
      fechaActual.getDate() < 9
        ? '0' + fechaActual.getDate()
        : '' + fechaActual.getDate();
    let mes =
      fechaActual.getMonth() < 9
        ? '0' + fechaActual.getMonth()
        : fechaActual.getMonth();
    var docDefinition = {
      content: [],
      pageMargins: [40, 110, 40, 90],
      header: this.getHeaderPickTicket(order),
      dontBreakRows: true,
      info: {
        title: 'Pick Ticket',
      },
      styles: {
        nombreCliente: {
          fontSize: 15,
          bold: true,
        },
        fechaCabecera: {
          fontSize: 9,
          bold: true,
        },
        numeroPaquetes: {
          fontSize: 32,
          bold: true,
        },
        codigoPedido: {
          fontSize: 9,
          bold: true,
        },
        subheader: {
          fontSize: 14,
          bold: true,
          markerColor: 'black',
        },
        text: {
          fontSize: 8,
          bold: true,
        },
        story: {
          italic: true,
          alignment: 'center',
          width: '50%',
        },
        tableExample: {
          margin: [0, 10, 0, 15],
        },
        totalSumary: {
          margin: [310, 10, 0, 0],
        },
        total: {
          margin: [310, 0, 0, 15],
        },
      },
    };

    //Please do not change the order of the methods
    //this.getHeaderPickTicket(order,docDefinition);
    this.getInformationTopPanelPickTicket(order, docDefinition);
    this.getPanelInformationPickTicket(order, docDefinition);
    this.getTableInformationPickTicket(order, docDefinition);
    this.getTableProductsPickTicket(order, docDefinition);
    this.getSignPick(order, docDefinition);

    this.pdfObj = pdfMake.createPdf(docDefinition);
  }

  getHeader(order) {
    return {
      pageBreak: 'after',
      columns: [
        {
          image: this.imagenPdfLogo,
          width: 150,
          height: 110,
          alignment: 'left',
          margin: [25, 0, 0, 0],
        },
        {
          text: 'SALES ORDER',
          style: 'nombreCliente',
          alignment: 'center',
          margin: [65, 70, 0, 20],
        },
        {
          text: this.getDateFormat(),
          style: 'fechaCabecera',
          alignment: 'right',
          margin: [0, 20, 40, 20],
        },
      ],
    };
  }

  getHeaderPickTicket(order) {
    return {
      pageBreak: 'after',
      columns: [
        {
          image: this.imagenPdfLogo,
          width: 150,
          height: 110,
          alignment: 'left',
          margin: [25, 0, 0, 0],
        },
        {
          text: 'Pick Ticket',
          style: 'nombreCliente',
          alignment: 'center',
          margin: [65, 70, 0, 20],
        },
        {
          text: this.getDateFormat(),
          style: 'fechaCabecera',
          alignment: 'right',
          margin: [0, 20, 40, 20],
        },
      ],
    };
  }

  getInformationTopPanel(order, docDefinition: any) {
    docDefinition.content.push(
      {
        text: `${this.infoHost.name} Location`,
        style: 'fechaCabecera',
        alignment: 'left',
        margin: [0, 20, 0, 0],
        bold: true,
      },
      {
        text: '5260 NW 167th Street',
        style: 'fechaCabecera',
        alignment: 'left',
        margin: [0, 0, 0, 0],
        bold: true,
      },
      {
        text: 'Phone : (305) 8282323',
        style: 'fechaCabecera',
        alignment: 'left',
        margin: [0, 0, 0, 0],
        bold: true,
      },
      {
        text: 'Fax : (786) 414-2008',
        style: 'fechaCabecera',
        alignment: 'left',
        margin: [0, 0, 0, 0],
        bold: true,
      },
      {
        text: 'Email: sales@igt-glasshardware.com',
        style: 'fechaCabecera',
        alignment: 'left',
        margin: [0, 0, 0, 0],
        bold: true,
      },
      {
        text: 'S.O# ' + order.code,
        style: 'fechaCabecera',
        alignment: 'right',
        margin: [0, -90, 0, 0],
        bold: true,
      },

      // resized QR
      { qr: order.code, fit: '80', margin: [0, 10, 0, 20], alignment: 'right' }
    );
  }

  getInformationTopPanelPickTicket(order, docDefinition: any) {
    docDefinition.content.push(
      {
        text: `${this.infoHost.name} Location`,
        style: 'fechaCabecera',
        alignment: 'left',
        margin: [0, 20, 0, 0],
        bold: true,
      },
      {
        text: '5260 NW 167th Street',
        style: 'fechaCabecera',
        alignment: 'left',
        margin: [0, 0, 0, 0],
        bold: true,
      },
      {
        text: 'Phone : (305) 8282323',
        style: 'fechaCabecera',
        alignment: 'left',
        margin: [0, 0, 0, 0],
        bold: true,
      },
      {
        text: 'Fax : (786) 414-2008',
        style: 'fechaCabecera',
        alignment: 'left',
        margin: [0, 0, 0, 0],
        bold: true,
      },
      {
        text: 'Email: sales@igt-glasshardware.com',
        style: 'fechaCabecera',
        alignment: 'left',
        margin: [0, 0, 0, 0],
        bold: true,
      }
    );
  }

  getPanelInformationPickTicket(order, docDefinition: any) {
    let nombreUsuario: string =
      this.user.first_name + ' ' + this.user.last_name;
    nombreUsuario.replace('null', '');
    docDefinition.content.push({
      style: 'tableExample',
      margin: [0, 10, 0, 10],
      table: {
        headerRows: 1,
        widths: [250, 200],
        body: [
          [
            { text: '', style: 'tableHeader' },
            { text: '', style: 'tableHeader' },
          ],
          [
            {
              text: `Picker: ${nombreUsuario.toUpperCase()}`,
              style: 'codigoPedido',
            },
            '',
          ],
          [
            {
              text: `User:  ${nombreUsuario.toUpperCase()}`,
              style: 'codigoPedido',
            },
            '',
          ],
          [
            {
              text: 'Delivery Date: ' + this.getDateFormat(),
              style: 'codigoPedido',
            },
            {
              text: 'Status: ' + this.currentOrder.order_state_name,
              style: 'codigoPedido',
            },
          ],
          [
            { text: `Carrier: ${this.orderTypes.name}`, style: 'codigoPedido' },
            { text: 'Type: Pick', style: 'codigoPedido' },
          ],
        ],
      },
      layout: 'noBorders',
    });
  }

  getTableInformationPickTicket(order, docDefinition: any) {
    this.currentOrder.client_name = this.currentOrder.client_name.replace(
      'null',
      ''
    );
    let documento =
      order.client !== undefined ? order.client.document : order.user.document;
    docDefinition.content.push({
      style: 'tableExample',
      table: {
        widths: [250, 250],
        body: [
          [
            { text: 'Customer Code: ' + documento, style: 'codigoPedido' },
            { text: 'Remarks: ', style: 'codigoPedido' },
          ],
          [
            {
              text: 'Customer Name: ' + this.currentOrder.client_name,
              style: 'codigoPedido',
            },
            { text: '$ ' + order.total_price, style: 'codigoPedido' },
          ],
          [
            { text: 'Document Order: ' + order.code, style: 'codigoPedido' },
            { text: '', style: 'codigoPedido' },
          ],
        ],
      },
    });
  }

  getPanelsInformation(order, docDefinition: any) {
    let directionBill = '';
    let directionShip = '';
    this.cliente.addresses.forEach((direction) => {
      if (direction.tag == 'bo_BillTo') {
        directionBill = direction.direction.split(',');
      }
      if (direction.tag == 'bo_ShipTo') {
        directionShip = direction.direction.split(',');
      }
    });
    //let directionShip = order.client.direction.split(",");

    docDefinition.content.push(
      {
        text: 'Bill to',
        style: 'codigoPedido',
        bold: true,
        margin: [0, 10, 0, 0],
      },
      {
        text: 'Ship to',
        style: 'codigoPedido',
        bold: true,
        margin: [255, -12, 0, 4],
      },
      {
        style: 'tableExample',
        margin: [0, 0, 10, 20],
        table: {
          widths: [237],
          body: [
            [
              {
                text: `${this.currentOrder.client_name} \n ${directionBill[0]} \n ${directionBill[1]} \n ${directionBill[2]}`,
                style: 'codigoPedido',
              },
            ],
          ],
        },
      },
      {
        style: 'tableExample',
        alignment: 'left',
        margin: [255, -73, 10, 20],
        table: {
          widths: [237],
          body: [
            [
              {
                text: `${this.currentOrder.client_name} \n ${directionShip[0]} \n ${directionShip[1]} \n ${directionShip[2]}`,
                style: 'codigoPedido',
              },
            ],
          ],
        },
      }
    );
  }

  getPanelPolicy(docDefinition) {
    docDefinition.content.push({
      style: 'tableExample',
      margin: [0, 20, 0, 20],
      table: {
        widths: [487],
        body: [
          [
            {
              text: `Return Policy:
              All returns/exchanges must be made within the 30 days of the original purchase date and products must be on the original packing. After 30 days we will not accept any returns /exchages. Brass, Oil Rbd Bronze and Matte Black finishes are final salewith no exceptions. There are NO refunds, only strore credit will be applied.`,
              style: 'codigoPedido',
            },
          ],
        ],
      },
    });
  }

  getTableDescriptionClient(order, docDefinition: any) {
    docDefinition.content.push({
      style: 'tableExample',
      table: {
        widths: [75, 75, 75, 75, 75, 75],
        body: [
          [
            {
              text: 'Sales Rep',
              bold: true,
              style: 'codigoPedido',
              alignment: 'center',
            },
            {
              text: 'Payment Terms',
              bold: true,
              style: 'codigoPedido',
              alignment: 'center',
            },
            {
              text: 'Carrier',
              bold: true,
              style: 'codigoPedido',
              alignment: 'center',
            },
            {
              text: 'P.O Number',
              bold: true,
              style: 'codigoPedido',
              alignment: 'center',
            },
            {
              text: 'Requested Ship Date ',
              bold: true,
              style: 'codigoPedido',
              alignment: 'center',
            },
            {
              text: 'Cuote',
              bold: true,
              style: 'codigoPedido',
              alignment: 'center',
            },
          ],
          [
            { text: '', style: 'codigoPedido' },
            { text: '', style: 'codigoPedido' },
            { text: `${this.orderTypes.name}`, style: 'codigoPedido' },
            { text: '', style: 'codigoPedido' },
            { text: this.getDateFormat(), style: 'codigoPedido' },
            { text: '', style: 'codigoPedido' },
          ],
        ],
      },
    });
  }

  getTableProducts(order, docDefinition: any) {
    let tabla = {
      style: 'tableExample',
      table: {
        widths: [75, 180, 75, 50, 75],
        body: [
          [
            {
              text: 'Number',
              bold: true,
              style: 'codigoPedido',
              alignment: 'center',
            },
            {
              text: 'Description',
              bold: true,
              style: 'codigoPedido',
              alignment: 'center',
            },
            {
              text: 'Unit Price',
              bold: true,
              style: 'codigoPedido',
              alignment: 'center',
            },
            {
              text: 'Quantity order',
              bold: true,
              style: 'codigoPedido',
              alignment: 'center',
            },
            { text: 'Total Price ', style: 'codigoPedido' },
          ],
        ],
      },
    };
    if (order.order_products != undefined) {
      for (let index = 0; index < order.order_products.length; index++) {
        tabla.table.body.push([
          {
            text: order.order_products[index].product.plu,
            style: 'codigoPedido',
          },
          {
            text: order.order_products[index].product.name,
            style: 'codigoPedido',
          },
          { text: order.order_products[index].price, style: 'codigoPedido' },
          { text: order.order_products[index].quantity, style: 'codigoPedido' },
          {
            text:
              order.order_products[index].price *
              order.order_products[index].quantity,
            style: 'codigoPedido',
          },
        ]);
      }
      docDefinition.content.push(tabla);
    } else {
      for (let index = 0; index < order.products.length; index++) {
        tabla.table.body.push([
          { text: order.products[index].plu, style: 'codigoPedido' },
          { text: order.products[index].name, style: 'codigoPedido' },
          { text: order.products[index].price, style: 'codigoPedido' },
          { text: order.products[index].quantity, style: 'codigoPedido' },
          {
            text: order.products[index].price * order.products[index].quantity,
            style: 'codigoPedido',
          },
        ]);
      }
      docDefinition.content.push(tabla);
    }
  }

  getTableProductsPickTicket(order, docDefinition: any) {
    let tabla = {
      style: 'tableExample',
      table: {
        widths: [50, 75, 180, 75, 75],
        body: [
          [
            {
              text: 'Quantity',
              bold: true,
              style: 'codigoPedido',
              alignment: 'center',
            },
            {
              text: 'Number',
              bold: true,
              style: 'codigoPedido',
              alignment: 'center',
            },
            {
              text: 'Description',
              bold: true,
              style: 'codigoPedido',
              alignment: 'center',
            },
            {
              text: 'Bin Location',
              bold: true,
              style: 'codigoPedido',
              alignment: 'center',
            },
            { text: 'WareHouse', style: 'codigoPedido' },
          ],
        ],
      },
    };
    if (order.order_products != undefined) {
      let list = order.order_products.sort((a, b) => {
        let textA = a.product.bin_location;
        let textB = b.product.bin_location;
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

      for (let index = 0; index < list.length; index++) {
        tabla.table.body.push([
          { text: list[index].quantity, style: 'codigoPedido' },
          { text: list[index].product.plu, style: 'codigoPedido' },
          { text: list[index].product.name, style: 'codigoPedido' },
          { text: list[index].product.bin_location, style: 'codigoPedido' },
          { text: this.infoHost.bo_default_warehouse, style: 'codigoPedido' },
        ]);
      }
      docDefinition.content.push(tabla);
    } else {
      let list = order.products.sort((a, b) => {
        let textA = a.bin_location;
        let textB = b.bin_location;
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });

      for (let index = 0; index < list.length; index++) {
        tabla.table.body.push([
          { text: list[index].quantity, style: 'codigoPedido' },
          { text: list[index].plu, style: 'codigoPedido' },
          { text: list[index].name, style: 'codigoPedido' },
          { text: list[index].bin_location, style: 'codigoPedido' },
          { text: this.infoHost.bo_default_warehouse, style: 'codigoPedido' },
        ]);
      }
      docDefinition.content.push(tabla);
    }
  }

  getTotalSumary(order, docDefinition: any) {
    docDefinition.content.push({
      style: 'totalSumary',
      table: {
        headerRows: 1,
        widths: [100, 70],
        body: [
          [
            { text: '', style: 'tableHeader' },
            { text: '', style: 'tableHeader' },
          ],
          [
            { text: 'Subtotal:   ', style: 'codigoPedido' },
            { text: order.subtotal, style: 'codigoPedido' },
          ],
          [
            { text: 'Sale Tax:   ', style: 'codigoPedido' },
            {
              text: order.tax != undefined ? order.tax : 0,
              style: 'codigoPedido',
            },
          ],
          [
            { text: 'Freiht:     ', style: 'codigoPedido' },
            { text: 0, style: 'codigoPedido' },
          ],
        ],
      },
      layout: 'headerLineOnly',
    });
    docDefinition.content.push({
      style: 'total',
      table: {
        headerRows: 1,
        widths: [100, 70],
        body: [
          [
            { text: '', style: 'tableHeader' },
            { text: '', style: 'tableHeader' },
          ],
          [
            { text: 'Total:   ', style: 'codigoPedido' },
            { text: order.total_price, style: 'codigoPedido' },
          ],
        ],
      },
      layout: 'headerLineOnly',
    });
  }

  getSignPick(order, docDefinition: any) {
    docDefinition.content.push({
      style: 'tableExample',
      margin: [0, 20, 0, 10],
      table: {
        headerRows: 1,
        widths: [350, 140],
        body: [
          [
            { text: '', style: 'tableHeader' },
            { text: '', style: 'tableHeader' },
          ],
          [
            {
              text: 'I HEREBY ACKNOWLEDGE RECEIPT AND DELEVIRY OF THE GOODS DESCRIBED ON THIS PICK',
              style: 'text',
            },
            '_________________________',
          ],
          [
            {
              text: 'LISTAND THAT SAID GOODS HAVE BEEN INSPECTED AND ARE WITHOUT DEFECTS',
              style: 'text',
            },
            { text: 'CUSTOMER SIGNATURE', style: 'text' },
          ],
        ],
      },
      layout: 'noBorders',
    });
  }
  getPdfSalesOrder(order: any, tipoLogo: number) {
    if (order.data != undefined) {
      order = order.data;
      this.cliente = order.user;
      this.storage.get('orderTypes').then((orderTypes) => {
        orderTypes.forEach((element) => {
          if (element.id === order.order_type_id) {
            this.orderTypes = element;
            let imgPickUpGo = 'assets/img/order/imgpsh_fullsize.png';
            let imgDeliveri = 'assets/img/order/imgpsh_fullsize.png';
            this.getImagePdfLogo(
              tipoLogo == 0 ? imgPickUpGo : imgDeliveri,
              tipoLogo == 0 ? 'image/png' : 'image/png'
            ).then((imageLogo) => {
              this.imagenPdfLogo = imageLogo;
              this.createPdfOrderSale(order);
              this.downloadPdfOrderSale();
            });
          }
        });
      });
    } else {
      this.cartService.getAllClients().then((rs: any) => {
        rs.data.filter((cliente) => {
          if (cliente.id === this.orderSelected.client_id) {
            this.cliente = cliente;
            if (order) {
              this.storage.get('orderTypes').then((orderTypes) => {
                orderTypes.forEach((element) => {
                  if (element.id === order.order_type_id) {
                    this.orderTypes = element;
                    let imgPickUpGo = 'assets/img/order/imgpsh_fullsize.png';
                    let imgDeliveri = 'assets/img/order/imgpsh_fullsize.png';
                    this.getImagePdfLogo(
                      tipoLogo == 0 ? imgPickUpGo : imgDeliveri,
                      tipoLogo == 0 ? 'image/png' : 'image/png'
                    ).then((imageLogo) => {
                      this.imagenPdfLogo = imageLogo;
                      this.createPdfOrderSale(order);
                      this.downloadPdfOrderSale();
                    });
                  }
                });
              });
            }
          }
        });
      });
    }
  }

  getPdfPickTicket(order: any, tipoLogo: number) {
    if (order.data != undefined) {
      order = order.data;
      this.storage.get('orderTypes').then((orderTypes) => {
        orderTypes.forEach((element) => {
          if (element.id === order.order_type_id) {
            this.orderTypes = element;
            let imgPickUpGo = 'assets/img/order/imgpsh_fullsize.png';
            let imgDeliveri = 'assets/img/order/imgpsh_fullsize.png';
            this.getImagePdfLogo(
              tipoLogo == 0 ? imgPickUpGo : imgDeliveri,
              tipoLogo == 0 ? 'image/png' : 'image/png'
            ).then((imageLogo) => {
              this.imagenPdfLogo = imageLogo;
              this.createPdfPickTicket(order);
              this.downloadPdfPickTicket();
            });
          }
        });
      });
    } else {
      if (order) {
        this.storage.get('orderTypes').then((orderTypes) => {
          orderTypes.forEach((element) => {
            if (element.id === order.order_type_id) {
              this.orderTypes = element;
              let imgPickUpGo = 'assets/img/order/imgpsh_fullsize.png';
              let imgDeliveri = 'assets/img/order/imgpsh_fullsize.png';
              this.getImagePdfLogo(
                tipoLogo == 0 ? imgPickUpGo : imgDeliveri,
                tipoLogo == 0 ? 'image/png' : 'image/png'
              ).then((imageLogo) => {
                this.imagenPdfLogo = imageLogo;
                this.createPdfPickTicket(order);
                this.downloadPdfPickTicket();
              });
            }
          });
        });
      }
    }
  }

  getImagePdfLogo(url, outputFormat) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = () => {
        let canvas = <HTMLCanvasElement>document.createElement('CANVAS'),
          ctx = canvas.getContext('2d'),
          dataURL;
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        //callback(dataURL);
        canvas = null;
        resolve(dataURL);
      };
      img.src = url;
    });
  }

  downloadPdfOrderSale() {
    this.utilsService
      .presentLoading(this.translateService.instant('all.loading'))
      .then(() => {
        let filePath = this.file.externalDataDirectory;
        let fechaActual = new Date()
          .toJSON()
          .split(':')
          .join('')
          .replace('/', '-')
          .replace('.', '-');
        //4 pulgadas de ancho * 6 pulgadas de alto
        if (this.plt.is('cordova')) {
          this.pdfObj.getBuffer((buffer) => {
            var blob = new Blob([buffer], { type: 'application/pdf' });

            // Save the PDF to the data Directory of our App
            try {
              this.file.createFile(
                this.file.externalDataDirectory,
                'Sale-Order-' + fechaActual + '.pdf',
                true
              );
              this.file
                .writeFile(
                  filePath,
                  'Sale-Order-' + fechaActual + '.pdf',
                  blob,
                  {
                    replace: true,
                  }
                )
                .then((fileEntry) => {
                  // Open the PDf with the correct OS tools
                  this.fileOpener
                    .open(fileEntry.nativeURL, 'application/pdf')
                    .then((respuesta) => {
                      this.utilsService.dismissLoading();
                    });
                });
            } catch (error) {
              console.log('error', error);
              this.utilsService.dismissLoading();
            }
          });
        } else {
          // On a browser simply use download!
          this.pdfObj.download('Sale-Order-' + fechaActual + '.pdf', () => {
            this.utilsService.dismissLoading();
          });
        }
      });
  }

  downloadPdfPickTicket() {
    this.utilsService
      .presentLoading(this.translateService.instant('all.loading'))
      .then(() => {
        let filePath = this.file.externalDataDirectory;
        let fechaActual = new Date()
          .toJSON()
          .split(':')
          .join('')
          .replace('/', '-')
          .replace('.', '-');
        //4 pulgadas de ancho * 6 pulgadas de alto
        if (this.plt.is('cordova')) {
          this.pdfObj.getBuffer((buffer) => {
            var blob = new Blob([buffer], { type: 'application/pdf' });

            // Save the PDF to the data Directory of our App
            try {
              this.file.createFile(
                this.file.externalDataDirectory,
                'Pick-Ticket-' + fechaActual + '.pdf',
                true
              );
              this.file
                .writeFile(
                  filePath,
                  'Pick-Ticket-' + fechaActual + '.pdf',
                  blob,
                  {
                    replace: true,
                  }
                )
                .then((fileEntry) => {
                  // Open the PDf with the correct OS tools
                  this.fileOpener
                    .open(fileEntry.nativeURL, 'application/pdf')
                    .then((respuesta) => {
                      this.utilsService.dismissLoading();
                    });
                });
            } catch (error) {
              console.log('error', error);
              this.utilsService.dismissLoading();
            }
          });
        } else {
          // On a browser simply use download!
          this.pdfObj.download('Pick-Ticket-' + fechaActual + '.pdf', () => {
            this.utilsService.dismissLoading();
          });
        }
      });
  }

  getDateFormat(): string {
    let fechaActual = new Date();
    let dia =
      fechaActual.getDate() < 9
        ? '0' + fechaActual.getDate()
        : '' + fechaActual.getDate();
    let mes =
      fechaActual.getMonth() < 9
        ? '0' + fechaActual.getMonth()
        : fechaActual.getMonth();

    return dia + '/' + mes + '/' + fechaActual.getFullYear();
  }
}
