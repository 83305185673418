import { NgModule } from '@angular/core';
import { IonicModule } from "@ionic/angular";
import { CommonModule } from '@angular/common';
import { PipesModule } from "../pipes/pipes.module";
import { TranslateModule } from "@ngx-translate/core";
import { ProductComponent } from './product/product.component';
import { DirectivesModule } from "../directives/directives.module";
import { ProductListComponent } from './product-list/product-list.component';
import { ShoppingListComponent } from './shopping-list/shopping-list.component';
import { DetailProductPage } from "../pages/detail-product/detail-product";
import { FormsModule } from "@angular/forms";
import { SyncIconComponent } from './sync-icon/sync-icon.component';
import { HeaderComponent } from './header/header.component';
import { TotalSummaryComponent } from './total-summary/total-summary.component';
import { EmptyComponentComponent } from './empty-component/empty-component.component';
import { CardDetailOrderComponent } from './card-detail-order/card-detail-order.component';
import { MaterialModule } from '../modules/material/material.module';
import { CardListOrderComponent } from './card-list-order/card-list-order.component';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { File } from "@ionic-native/file/ngx";

@NgModule({
  declarations: [
    ProductComponent,
    ProductListComponent,
    ShoppingListComponent,
    DetailProductPage,
    SyncIconComponent,
    HeaderComponent,
    TotalSummaryComponent,
    EmptyComponentComponent,
    CardDetailOrderComponent,
    CardListOrderComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    DirectivesModule,
    TranslateModule.forChild(),
    FormsModule,
    MaterialModule
  ],
  exports:[
    ProductListComponent,
    ShoppingListComponent,
    SyncIconComponent,
    HeaderComponent,
    TotalSummaryComponent,
    EmptyComponentComponent,
    CardDetailOrderComponent,
    CardListOrderComponent,
  ],
  entryComponents:[
    ProductComponent,
    DetailProductPage
  ],providers:[
    File,
    FileOpener
  ]
})
export class ComponentsModule { }
