import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ValidUrlImageDirective } from "./valid-url-image";

@NgModule({
  declarations: [ValidUrlImageDirective],
  imports: [CommonModule],
  exports: [ValidUrlImageDirective],
})
export class DirectivesModule {}
