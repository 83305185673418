import { Injectable, Inject, forwardRef } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { CityService } from "./city.service";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(public http: HttpClient, @Inject(forwardRef(() => CityService)) public cityService: CityService) {}

  public getUrl() {
    return this.cityService._urlAdmin;
  }

  get(endpoint: string, token: any, params?: any, reqOpts?: any) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + token,
    });

    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
        headers: headers,
      };
    }

    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }

    return this.http.get(this.cityService._urlApi + "/" + endpoint, reqOpts);
  }

  getFree(endpoint: string, params?: any, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
      };
    }

    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }

    return this.http.get(this.cityService._urlApi + "/" + endpoint, reqOpts);
  }
  postFile(endpoint: string, formData: FormData, token: any, reqOpts?: any) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + token,
    });

    return this.http.post(this.cityService._urlApi + "/" + endpoint, formData, { headers });
  }

  post(endpoint: string, body: any, token: any, reqOpts?: any) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + token,
    });

    return this.http.post(this.cityService._urlApi + "/" + endpoint, body, { headers });
  }

  postFree(endpoint: string, body: any, reqOpts?: any) {
    return this.http.post(this.cityService._urlApi + "/" + endpoint, body, reqOpts);
  }

  put(endpoint: string, body: any, token: any, reqOpts?: any) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + token,
    });

    return this.http.put(this.cityService._urlApi + "/" + endpoint, body, { headers });
  }

  putFree(endpoint: string, body: any, reqOpts?: any) {
    return this.http.put(this.cityService._urlApi + "/" + endpoint, body, reqOpts);
  }

  delete(endpoint: string, token, reqOpts?: any) {
    let headers = new HttpHeaders({
      Authorization: "Bearer " + token,
    });

    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
        headers: headers,
      };
    }

    return this.http.delete(this.cityService._urlApi + "/" + endpoint, reqOpts);
  }

  patch(endpoint: string, body: any, reqOpts?: any) {
    return this.http.patch(this.cityService._urlApi + "/" + endpoint, body, reqOpts);
  }
}
