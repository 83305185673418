import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quantityDetailProduct',
})
export class QuantityDetailProductPipe implements PipeTransform {
  transform(quantity: number, product_id:number,productsCart:any) {    
    if (productsCart && productsCart.length) {
      let statusQuantity = false;
      let _quantity = 0;
      for (let i = 0; i < productsCart.length; i++) {
        if (productsCart[i].id == product_id) {
            statusQuantity = true;
            _quantity = productsCart[i].quantity
        }
      }
      if (statusQuantity) {
          quantity = _quantity;
          statusQuantity = false;
          _quantity = 0;
      }
    }else{
      quantity = 0;
    }
    return quantity;
  }
}
