import { Component, Input, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit {
  @Input() height: any = '300px';
  @Input() products: any = [];
  @Input() origin = 'others';
  constructor(public cartProvider: CartService) {}

  ngOnInit() {}
}
