import { Injectable } from "@angular/core";
import { Platform, AlertController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

import { Market } from "@ionic-native/market/ngx";

import { CityService } from "./city.service";
import { UtilsService } from "./utils.service";
import { EndVersionPage } from "../pages/end-version/end-version";
import { StorageService } from "./storage.service";
import { ConnectionService } from "./connection.service";

@Injectable({
  providedIn: "root",
})
export class AuthRouteService {
  backButtonSubscription: any;
  constructor(
    private platform: Platform,
    public alertController: AlertController,
    private translate: TranslateService,
    private market: Market,
    public modalCtrl: ModalController,
    public cityService: CityService,
    public utilsService: UtilsService,
    private storage: StorageService,
    private connectionService: ConnectionService
  ) {}

  validateLoginUser() {
    return new Promise((resolve, reject) => {
      this.storage
        .get("HAS_LOGGED_IN")
        .then((HAS_LOGGED_IN) => {
          this.cityService.getInfoHost();
          this.utilsService._HAS_LOGGED_IN = HAS_LOGGED_IN;
          if(!this.connectionService.statusConnection && HAS_LOGGED_IN){
            resolve("existsUser");
          }
          if(!this.connectionService.statusConnection && !HAS_LOGGED_IN){
            resolve("notExistsUser");
          }
          if (this.platform.is("cordova")) {
            this.cityService.getParameters().subscribe(
              (resp) => {
                if (resp["status"] == "success") {
                  let infoEndVersion = {
                    image_end_version: resp["parameters"].image_end_version,
                    text_end_version: resp["parameters"].text_end_version,
                    link_end_version: resp["parameters"].link_end_version,
                    end_version_home: resp["parameters"].end_version_home,
                  };
                  this.storage.set("infoEndVersion", infoEndVersion);
                  if (resp["parameters"].end_version) {
                    resolve("showEndVersionModal");
                    this.showEndVersionModal(
                      resp["parameters"].image_end_version,
                      resp["parameters"].text_end_version,
                      resp["parameters"].link_end_version
                    );
                  } else {
                    if (HAS_LOGGED_IN) {
                      resolve("existsUser");
                    } else {
                      this.storage.get("productsCart").then((productsCart) => {
                        if (productsCart) {
                          this.storage.cleaeAll();
                        }
                        resolve("notExistsUser");
                      });
                    }
                  }
                }
              },
              (error) => {
                console.log("error initialService.getParameters: ", error);
              }
            );
          } else {
            this.cityService.getParameters().subscribe((resp) => {
              this.storage.get("infoUser").then((infoUser) => {
                let infoEndVersion = {
                  image_end_version: resp["parameters"].image_end_version,
                  text_end_version: resp["parameters"].text_end_version,
                  link_end_version: resp["parameters"].link_end_version,
                  end_version_home: resp["parameters"].end_version_home,
                };
                this.storage.set("infoEndVersion", infoEndVersion);
                if (infoUser) {
                  resolve("existsUser");
                } else {
                  resolve("notExistsUser");
                }
              });
            },(error=>{
              console.log("error getParameters web: ", error);
            }));
          }
        })
        .catch((error) => {
          reject(false);
          console.log("error getHAS_LOGGED_IN: ", error);
        });
    });
  }
  async showConfirmVersionAppOld(appId) {
    const alert = await this.alertController.create({
      header: this.translate.instant("TITLE_ALERT_UPDATE_APP"),
      message: this.translate.instant("TEXT_ALERT_UPDATE_APP"),
      buttons: [
        {
          text: this.translate.instant("BUTTON1_ALERT_UPDATE_APP"),
          handler: () => {
            this.exitApp();
          },
        },
        {
          text: this.translate.instant("BUTTON2_ALERT_UPDATE_APP"),
          handler: () => {
            this.market.open(appId);
            this.exitApp();
          },
        },
      ],
    });
    await alert.present();
  }

  async showEndVersionModal(image_end_version, text_end_version, link_end_version) {
    const modal = await this.modalCtrl.create({
      component: EndVersionPage,
      componentProps: { image_end_version, text_end_version, link_end_version },
    });
    await modal.present();

    await modal.onWillDismiss().then(() => {
      this.exitApp();
    });
  }

  exitApp() {
    this.backButtonSubscription = this.platform.backButton.subscribe(() => {
      navigator["app"].exitApp();
    });
  }
}
