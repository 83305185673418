import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "productInCart",
  pure: false,
})
export class ProductInCartPipe implements PipeTransform {
  transform(listProducts: any, productsCart: any, origin: any) {
    if (listProducts.length && origin != "cart" && origin != "orderDetail") {
      for (let j = 0; j < listProducts.length; j++) {
        let statusInCart = false;
        let quantity = 0;
        let quantityGoblal = 0;
        listProducts[j].addCart = statusInCart;
        listProducts[j].quantity = quantity;
        listProducts[j].quantityGoblal = quantityGoblal;
        if (productsCart && productsCart.length) {
          let exists = productsCart.find((product) => product.id == listProducts[j].id);
          if (exists) {
            for (let i = 0; i < productsCart.length; i++) {
              if (listProducts[j].id == productsCart[i].id) {
                statusInCart = true;
                listProducts[j].indexCart = i;
                quantity = productsCart[i].quantity;
                quantityGoblal += quantity;
              }
            }
            if (statusInCart) {
              listProducts[j].addCart = true;
              listProducts[j].quantity = quantity;
              listProducts[j].quantityGoblal = quantityGoblal;
            }
          }
        }
        listProducts[j].attributeText = this.typeAttribute(listProducts[j].product_attributes);
        listProducts[j] = this.discount(listProducts[j]);
        listProducts[j] = this.flash(listProducts[j]);
      }
    }
    return listProducts;
  }

  typeAttribute(object) {
    let attributeText = true;
    if (object && object != "") {
      let item = Object.entries(object);
      for (let i = 0; i < item.length; i++) {
        let attributes: any = item[i][1];
        for (let j = 0; j < attributes.length; j++) {
          let element = attributes[j];
          if (element.attribute.attribute_type_id != 1) {
            attributeText = false;
          }
        }
      }
    }
    return attributeText;
  }

  discount(product) {
    product.priceAfterDiscount = 0;
    if (product.percentage_discount > 0 && product.start_discount && product.limit_discount && product.limit_hour_discount) {
      let currentDate = new Date();
      let dateStartDiscount = product.start_discount.split("-");
      let startDiscount = new Date(parseInt(dateStartDiscount[0]), parseInt(dateStartDiscount[1]) - 1, parseInt(dateStartDiscount[2]));
      if (startDiscount.getTime() < currentDate.getTime()) {
        let dateLimitDiscount = product.limit_discount.split("-");
        let timeLimitDiscount = product.limit_hour_discount.split(":");
        let dateDiscount = new Date(
          parseInt(dateLimitDiscount[0]),
          parseInt(dateLimitDiscount[1]) - 1,
          parseInt(dateLimitDiscount[2]),
          parseInt(timeLimitDiscount[0]),
          parseInt(timeLimitDiscount[1]),
          parseInt(timeLimitDiscount[2])
        );
        if (dateDiscount.getTime() > currentDate.getTime()) {
          product.priceAfterDiscount = Math.round(product.price - (product.price * product.percentage_discount) / 100);
          product.priceAfterFlash = 0;
        }
      }
    }
    return product;
  }

  flash(product) {
    product.priceAfterFlash = 0;
    if (product.flash_price > 0 && product.start_flash_discount && product.limit_flash_discount && product.limit_hour_flash_discount) {
      let currentDate = new Date();
      let dateStartFlash = product.start_flash_discount.split("-");
      let startFlash = new Date(parseInt(dateStartFlash[0]), parseInt(dateStartFlash[1]) - 1, parseInt(dateStartFlash[2]));
      if (startFlash.getTime() < currentDate.getTime()) {
        let dateLimitFlash = product.limit_flash_discount.split("-");
        let timeLimitFlash = product.limit_hour_flash_discount.split(":");
        let dateFlash = new Date(
          parseInt(dateLimitFlash[0]),
          parseInt(dateLimitFlash[1]) - 1,
          parseInt(dateLimitFlash[2]),
          parseInt(timeLimitFlash[0]),
          parseInt(timeLimitFlash[1]),
          parseInt(timeLimitFlash[2])
        );
        if (dateFlash.getTime() > currentDate.getTime()) {
          product.priceAfterFlash = parseInt(product.flash_price);
          product.priceAfterDiscount = 0;
        }
      }
    }
    return product;
  }
}
