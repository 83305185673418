import { Pipe, PipeTransform } from "@angular/core";
import { UtilsService } from "../services/utils.service";

@Pipe({
  name: "validateSimbolicTickets",
})
export class ValidateSimbolicTicketsPipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {}
  transform(tickets: any): any {
    if (tickets && tickets.length) {
      for (let i = 0; i < tickets.length; i++) {
        let ticket = tickets[i];
        if (this.utilsService.validateDate(ticket.start_date, "start") && this.utilsService.validateDate(ticket.end_date, "end")) {
          ticket.is_salable = true;
        } else {
          ticket.is_salable = false;
        }
      }
    }
    return tickets;
  }
}
