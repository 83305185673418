import { Component, Input, OnInit } from "@angular/core";
import { ModalController, IonRouterOutlet } from "@ionic/angular";

import { DetailProductPage } from "../../pages/detail-product/detail-product";

import { CartService } from "../../services/cart.service";
import { CityService } from "../../services/city.service";
import { PhotoViewer } from "@ionic-native/photo-viewer/ngx";
import { UtilsService } from "src/app/services/utils.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-shopping-list",
  templateUrl: "./shopping-list.component.html",
  styleUrls: ["./shopping-list.component.scss"],
})
export class ShoppingListComponent implements OnInit {
  @Input() origin = "others";
  @Input() listProducts = [];
  @Input() disabled_sliding = true;
  WIDTH_INCREMENT:number = 10;
  WIDTH_BASE:number = 20;
  constructor(
    public modalCtrl: ModalController,
    private routerOutlet: IonRouterOutlet,
    public cartProvider: CartService,
    public cityProvider: CityService,
    private photoViewer: PhotoViewer,
    public utilsService: UtilsService,
    public translateService: TranslateService
  ) {}

  ngOnInit() {}

  async openModalDetailProduct(product) {
    const modal = await this.modalCtrl.create({
      component: DetailProductPage,
      swipeToClose: true,
      presentingElement: this.routerOutlet.nativeEl,
      componentProps: { product, origin: this.origin },
    });
    await modal.present();
  }

  previewImage(myImage) {
    console.log(myImage);
    this.photoViewer.show(myImage.src);
  }

  addProductCart(product) {
    if (product.attributeText) {
      this.cartProvider.addProduct(product, 1);
    } else {
      this.openModalDetailProduct(product);
    }
  }

  checkInventory(product){
    this.cartProvider.getGlobalInventoryForPLU(product.plu).then( (res:any) => {
      let message = '';

      res.forEach(elem => {
        message = message + '* ' + elem.city + ': ' + elem.qty_warehouse + '<br>';
      });
      this.utilsService.presentAlertInfo(this.translateService.instant('UNITS_AVAILABLE_BRANCHES'),'',message);
    });
  }

  addQuantityProduct(product) {
    this.cartProvider.updateQuantity(product, product.quantity + 1, "add");
  }

  removeQuantityProduct(product) {
    this.cartProvider.updateQuantity(product, product.quantity - 1, "remove");
  }

  deleteProductCart(product) {
    this.cartProvider.deleteProduct(product);
  }

  changeQuantity(event,product){
    let oldProducts = this.listProducts;
    let currentQuantity = event.detail.value;
    if (currentQuantity > 0) {
      if(!this.cartProvider.updateQuantity(product, parseInt(currentQuantity), "detail_product")){
        this.listProducts = [];
        setTimeout(() => {
          this.listProducts = oldProducts;
        }, 200);
      }
    }
  }

  getWidthQuantity(quantity){
    let width = this.WIDTH_BASE;
    if (quantity.toString().length > 1) {
      width = (quantity.toString().length * this.WIDTH_INCREMENT) + this.WIDTH_INCREMENT;
    }
    return width;
  }
}
