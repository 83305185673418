import { Directive, Input, AfterViewInit, ElementRef } from "@angular/core";
@Directive({
  selector: "[valid-url-image]",
})
export class ValidUrlImageDirective implements AfterViewInit {
  @Input() src: any;
  constructor(private imageRef: ElementRef) {}

  ngAfterViewInit(): void {
    const img = new Image();
    img.onload = () => {
      this.setImage(this.src);
    };

    img.onerror = () => {
      this.setImage("assets/img/default.png");
    };

    img.src = this.src;
  }

  private setImage(src: string) {
    this.imageRef.nativeElement.setAttribute("src", src);
  }
}
