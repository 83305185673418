import { Component, OnInit } from "@angular/core";
import { NavParams, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { SucursalService } from "../../services/sucursal.service";
import { CityService } from "../../services/city.service";
import { UtilsService } from "../../services/utils.service";
import { StorageService } from "src/app/services/storage.service";

@Component({
  selector: "page-sucursal-connection",
  templateUrl: "sucursal-connection.html",
})
export class SucursalConnectionPage implements OnInit {
  listSucursals: any;
  statusButtonBack: boolean;
  constructor(
    public navParams: NavParams,
    public sucursalProvider: SucursalService,
    public cityProvider: CityService,
    public utilsService: UtilsService,
    public translate: TranslateService,
    public modalCtrl: ModalController,
    private storage: StorageService
  ) {
    this.statusButtonBack = this.navParams.get("buttonBack");
  }

  ngOnInit() {}

  ionViewDidLoad() {}

  ionViewWillEnter() {
    this.utilsService.presentLoading(this.translate.instant("all.loading")).then(() => {
      this.sucursalProvider.listSucursals().subscribe(
        (resp) => {
          this.utilsService.dismissLoading();
          this.listSucursals = resp["listSucursals"];
          this.storage.set("listSucursals", this.listSucursals);
        },
        (error) => {
          this.utilsService.dismissLoading();
          console.log("error listSucursals: ", error);
        }
      );
    });
  }

  sucursalSelected(sucursal) {
    this.storage.set("sucursalSelected", sucursal);
    this.closeModal(sucursal);
  }

  closeModal(params) {
    this.modalCtrl.dismiss(params);
  }
}
