import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-empty-component",
  templateUrl: "./empty-component.component.html",
  styleUrls: ["./empty-component.component.scss"],
})
export class EmptyComponentComponent implements OnInit {
  @Input() emptySettingsSlide: any = [];
  slideOpts = {
    allowSlidePrev: false,
    allowSlideNext: false,
    initialSlide: 1,
    speed: 400,
  };
  constructor() {}

  ngOnInit() {}
}
