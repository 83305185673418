import { Injectable } from '@angular/core';
import { ToastController, LoadingController, AlertController } from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  loading: any;
  toast: any;
  alert: any;
  infoHost:any;
  _HAS_LOGGED_IN: any = false;
  constructor(public loadingController: LoadingController, public toastController: ToastController, public alertController: AlertController) {}

  async presentLoading(message) {
    this.loading = await this.loadingController.create({
      message: message,
      spinner: "lines",
      translucent: true,
      mode: "ios",
    });
    return await this.loading.present();
  }

  async dismissLoading() {
    await this.loading.dismiss();
  }

  getBackgroundImage() {
    return "../../assets/img/bg.png";
  }

  async presentToast(duration, color, position, message) {
    if(!this.toast){
      this.toast = await this.toastController.create({
        message: message,
        position: position,
        color: color,
        duration: duration,
      });
      this.toast.present();
    }
    this.toast.onDidDismiss().then(() => {
      this.toast = null;
    });
  }

  async presentAlertInfo(header: string, subheader?: string, message?: string) {
    if(!this.alert){
      this.alert = await this.alertController.create({
        cssClass: "my-custom-class",
        header: header,
        subHeader: subheader,
        message: message,
        buttons: ["OK"],
        backdropDismiss: false,
      });

      this.alert.present();
    }
    this.alert.onDidDismiss().then(()=>{
      this.alert = null;
    });
  }

  async presentAlertPopup(msg) {
    if(!this.alert){
      this.alert = await this.alertController.create({
        message: msg,
        buttons: ['OK'],
        backdropDismiss: false,
      });

      this.alert.present();
    }
    this.alert.onDidDismiss().then(()=>{
      this.alert = null;
    });
  }

  currentDate() {
    let today = new Date();
    let date = today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2, "0") + "-" + today.getDate().toString().padStart(2, "0");
    let time =
      (today.getHours() < 10 ? "0" + today.getHours() : today.getHours()) +
      ":" +
      (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes()) +
      ":" +
      (today.getSeconds() < 10 ? "0" + today.getSeconds() : today.getSeconds());
    let currentDate = date + " " + time;
    return currentDate;
  }

  currentDateToISOString(date) {
    return date.replace(" ", "T") + ".455-05:00";
  }

  // valida si una fecha es valida basada con la fecha actuals
  validateDate(date: any, type: string) {
    let currentDate = new Date();
    if (date && date != "") {
      let dateStart = date.slice(0, -9).split("-");
      let timeStart = date.slice(10).split(":");
      let newDateValidate = new Date(
        parseInt(dateStart[0]),
        parseInt(dateStart[1]) - 1,
        parseInt(dateStart[2]),
        parseInt(timeStart[0]),
        parseInt(timeStart[1]),
        parseInt(timeStart[2])
      );
      if (newDateValidate.getTime() < currentDate.getTime() && type == "start") {
        return true;
      }
      if (newDateValidate.getTime() > currentDate.getTime() && type == "end") {
        return true;
      }
    }
    return false;
  }
}
