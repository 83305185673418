import { Component, Input, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { CityService } from 'src/app/services/city.service';
import { ModalController, IonRouterOutlet } from '@ionic/angular';
import { DetailProductPage } from '../../pages/detail-product/detail-product';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UtilsService } from 'src/app/services/utils.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  @Input() product: any;
  @Input() origin: any;
  user: any;
  private _unsubscribeAll: Subject<any>;

  constructor(
    public cartProvider: CartService,
    public cityProvider: CityService,
    public modalCtrl: ModalController,
    private routerOutlet: IonRouterOutlet,
    private photoViewer: PhotoViewer,
    public utilsService: UtilsService,
    public translateService: TranslateService
  ) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.cartProvider.onUserChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((value) => {
        this.user = value;
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  async openModalDetailProduct(product) {
    const modal = await this.modalCtrl.create({
      component: DetailProductPage,
      swipeToClose: true,
      presentingElement: this.routerOutlet.nativeEl,
      componentProps: { product, origin: this.origin },
    });
    await modal.present();
  }

  checkInventory(product){
    this.cartProvider.getGlobalInventoryForPLU(product.plu).then( (res:any) => {
      let message = '';

      res.forEach(elem => {
        message = message + '* ' + elem.city + ': ' + elem.qty_warehouse + '<br>';
      });
      this.utilsService.presentAlertInfo(this.translateService.instant('UNITS_AVAILABLE_BRANCHES'),'',message);
    });
  }

  addProductCart(product) {
    if (this.user && this.user.id) {
      if (!product.quantity) {
        if (product && product.popup_active) {
          this.utilsService.presentAlertPopup(product.popup_text);
        }
        if (product.attributeText) {
          /// Se valida precio directo en special prices para el cliente.
          // let specialPriceForClient = this.user.bo_special_prices.find(
          //   (elem) => elem.product_id === product.id
          // );
          let specialPriceForClient = null;
          if (!specialPriceForClient) {
            // si no se encontro precio especial para el cliente, se busca precio manual en el item.
            if (product.bo_item_manual_prices) {
              // Si el producto tiene condicionados precios manuales
              specialPriceForClient = product.bo_item_manual_prices.find(
                (elem) => elem.bo_price_list_id === this.user.bo_price_list_id
              );
            }
          }
          /// Fin validacion
          this.cartProvider.addProduct(
            product,
            1,
            null,
            null,
            specialPriceForClient
          );
        } else {
          this.openModalDetailProduct(product);
        }
      } else {
        this.addQuantityProduct(product);
      }
    } else {
      this.utilsService.presentToast(
        3000,
        'warning',
        'top',
        this.translateService.instant('user_required')
      );
    }
  }

  addQuantityProduct(product) {
    this.cartProvider.updateQuantity(product, product.quantity + 1, 'add');
  }

  previewImage(myImage) {
    this.photoViewer.show(myImage.src);
  }
}
