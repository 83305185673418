import { Injectable } from '@angular/core';
import { ApiMainService } from "./api-main.service";
import { UtilsService } from "./utils.service";
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class CityService {
  _listCities: any = [];
  _urlGallery: string = "";
  _urlApi: string = "";
  _urlAdmin: string = "";
  _parametersMain:any;
  constructor(
    public apiMain: ApiMainService,
    public utilsService: UtilsService,
    private translate: TranslateService,
    private storage : StorageService
  ) {}

  getListCitiesConnection() {
    this.utilsService.presentLoading(this.translate.instant("all.loading")).then(() => {
      this.apiMain.getFree("listCitiesConnection").subscribe(
        (res: any) => {
          this.utilsService.dismissLoading();
          if (res) {
            this._listCities = res.listCities;
            this.storage.set("listCities", this._listCities);
          }
        },
        (err) => {
          this.utilsService.dismissLoading();
          console.error("Erro provider getListCitiesConnection", err);
        }
      );
    });
  }

  getInfoHost() {
    this.storage.get("infoHost").then((infoHost) => {
      if(infoHost){
        this.utilsService.infoHost = infoHost;
        this._urlApi = infoHost.url_api;
        this._urlAdmin = infoHost.url_admin;
        this._urlGallery = infoHost.url_gallery;
      }
    });
  }

  getParameters() {
    let seq = this.apiMain.getFree("parameters/info");
    seq.subscribe(
      (res: any) => {
        if (res.status == "success") {
          this._parametersMain = res.parameters;
          this.storage.set("parametersMain", res.parameters);
        }
      },
      (err) => {
        console.error("Error provider central getParameters", err);
      }
    );
    return seq;
  }
}
