import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(date: string) {
    if(date && date != ''){
      let newDate = new Date(date.replace(/-/g, "/"));
      return newDate;
    }
  }
}
