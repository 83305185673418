import { Component } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { StorageService } from './services/storage.service';
import { UserService } from './services/user.service';
import { UtilsService } from "./services/utils.service";
import { AlertController, NavController } from "@ionic/angular";
import { ConnectionService } from './services/connection.service';
import { CartService } from './services/cart.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'bill', url: '/sale', icon: 'basket' },
    { title: 'orders', url: '/orders', icon: 'document-text' },
  ];
  constructor(
    private translate: TranslateService,
    private storage: StorageService,
    public utilsService: UtilsService,
    public userService: UserService,
    public alertCtrl: AlertController,
    public navCtrl: NavController,
    private connectionService: ConnectionService,
    public cartService: CartService
    ) {}

  async ngOnInit() {
    await this.storage.init();
    this.initTranslate();
    this.suscribeNetworkConnection();
  }

  initTranslate() {
    this.translate.setDefaultLang("en");
    const browserLang = this.translate.getBrowserLang();

    if (browserLang == 'en' || browserLang == 'es') {
      this.translate.use(browserLang);
    } else {
      this.translate.use("en");
    }
  }

  async logout() {
    const confirm = await this.alertCtrl.create({
      header: this.translate.instant("TITLE_LOGOUT"),
      message: this.translate.instant("TEXT_LOGOUT"),
      buttons: [
        {
          text: "No",
          handler: () => {
            console.log("Disagree clicked");
          },
        },
        {
          text: "Si",
          handler: () => {
            this.cartService.productsCart = [];
            this.storage.cleaeAll();
            this.navCtrl.navigateRoot("/city-connection");
          },
        },
      ],
    });

    await confirm.present();
  }

  suscribeNetworkConnection(){
    this.connectionService.appIsOnline$.subscribe(online => {
      this.connectionService.statusConnection = online;
      if (online) {
          console.log("App is online");
          // this.cartService.initOrders();
          // this.cartService.syncAllOrders();
        } else {
          console.log("App is offline");
      }
    });
  }
}
