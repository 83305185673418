import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchProduct',
})
export class SearchProductPipe implements PipeTransform {
   transform(listProducts: any[], text: string) {     
     if (!listProducts || !text) {
       return listProducts;
     }
     text = text.toLowerCase();
     return listProducts.filter(product => product.name.toLowerCase().indexOf( text ) !== -1);
   }
}
