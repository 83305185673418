import { Component, OnInit } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-sync-icon',
  templateUrl: './sync-icon.component.html',
  styleUrls: ['./sync-icon.component.scss'],
})
export class SyncIconComponent implements OnInit {
  constructor(public cartService: CartService) {}

  ngOnInit() {}

  syncAllOrders() {
    // this.cartService.onSyncOrders.next();
    // this.cartService.syncAllOrders();

    // Refresh data.
    this.cartService.getAllClients();
    this.cartService.getAllProducts();
  }
}
