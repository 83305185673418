import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private oauthUrl = "login";
  private oauthRefreshUrl = "refreshToken";
  constructor(public api: ApiService, private storage: StorageService) {}
  /**
   * @param  {string} user
   * @param  {string} password
   *
   * * Este metodo se encarga de enviar a Passport los datos necesarios para la generación de un token.
   */
  refreshToken(refreshtoken: string) {
    let postData = {
      refreshtoken: refreshtoken,
    };

    return new Promise((resolve, reject) => {
      this.api.postFree(this.oauthRefreshUrl, postData).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("error refreshToken: ", err);
          reject(err);
        }
      );
    });
  }

  getAccessToken(user: string, password: string) {
    let postData = {
      username: user,
      password: password,
      type: "U",
    };
    return new Promise((resolve, reject) => {
      this.api.postFree(this.oauthUrl, postData).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          console.log("error getAccessToken: ", err);
          reject(err);
        }
      );
    });
  }

  itIsAuthorized(token) {
    return new Promise((resolve, reject) => {
      this.api.post("itIsAuthorized", {}, token).subscribe(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  /**
   * * Se elimina el token de la session local
   */
  invalidateToken() {
    // Delete token local
    this.storage.removeItem("token");
  }
}
