import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthRouteGuard } from "./guards/auth-route.guard";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'sale',
    pathMatch: 'full'
  },
  {
    path: 'sale',
    loadChildren: () => import('./pages/sale/sale.module').then( m => m.SalePageModule),
    canActivate: [AuthRouteGuard],
  },
  {
    path: "city-connection",
    loadChildren: () => import("./pages/city-connection/city-connection.module").then((m) => m.CityConnectionPageModule),
  },
  {
    path: "welcome",
    loadChildren: () => import("./pages/welcome/welcome.module").then((m) => m.WelcomePageModule),
  },
  {
    path: "login",
    loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "signup",
    loadChildren: () => import("./pages/signup/signup.module").then((m) => m.SignupPageModule),
  },
  {
    path: 'orders',
    loadChildren: () => import('./pages/orders/orders.module').then( m => m.OrdersPageModule)
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule)
  },
  {
    path: 'create-credit-card',
    loadChildren: () => import('./pages/create-credit-card/create-credit-card.module').then( m => m.CreateCreditCardPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
