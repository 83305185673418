import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UtilsService } from './utils.service';
import { SucursalConnectionPage } from '../pages/sucursal-connection/sucursal-connection';
import { ApiService } from './api.service';
import { ApiMainService } from './api-main.service';
import { AuthService } from './auth.service';
import { SucursalService } from './sucursal.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  _infoUser: any;
  _infoUserTest: any = 'super test';
  _documentType: any;
  tagsUserOneSignal: any;
  constructor(
    public api: ApiService,
    public apiMainProvider: ApiMainService,
    private storage: StorageService,
    public auth: AuthService,
    private utilsService: UtilsService,
    public sucursalProvider: SucursalService,
    public modalCtrl: ModalController
  ) {}

  signup(accountInfo: any, name_database: any) {
    return new Promise((resolve, reject) => {
      let info = {
        accountInfo: accountInfo,
        name_database: name_database,
      };
      this.apiMainProvider.postFree('user/signup', info).subscribe(
        (signup: any) => {
          if (signup.status == 'success') {
            // se consulta y almacena el token del usuario
            this.auth
              .getAccessToken(accountInfo.email, accountInfo.password)
              .then((data) => {
                this.storage.set('token', {
                  access_token: data['access_token'],
                  token_refresh: data['refresh_token'],
                });
                this.utilsService._HAS_LOGGED_IN = true;
                this.storage.set('HAS_LOGGED_IN', true);
                this.infoUser(accountInfo.email, data['access_token']).then(
                  (res) => {
                    // se valida si esta activo descuento envios para usuarios nuevos
                    this.registerDiscountShipments(data['access_token']);

                    // si guarda la sucursal en la informacion del usuario para la logica de productos por sucursal
                    this.storage
                      .get('sucursalSelected')
                      .then((sucursalSelected) => {
                        if (sucursalSelected) {
                          this.sucursalProvider.updateSucursalUser(
                            sucursalSelected.id,
                            data['access_token']
                          );
                        }
                      });
                    resolve(res);
                  }
                );
              })
              .catch((error) => {
                console.log('error getTokenUser: ', error);
                reject(error);
              });
          } else {
            reject(signup);
          }
        },
        (err) => {
          console.error('error provider signup: ', err);
          reject(err);
        }
      );
    });
  }

  uploadProfilePhoto(formData: FormData, token_access: any) {
    return new Promise((resolve, reject) => {
      this.api
        .postFile('user/uploadImageProfile', formData, token_access)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          (err) => {
            reject(err);
            console.error('error provider uploadProfilePhoto: ', err);
          }
        );
    });
  }

  infoUser(email: any, token_access: any) {
    return new Promise((resolve, reject) => {
      let data = {
        email: email,
      };
      this.api.post('user/info', data, token_access).subscribe(
        (res: any) => {
          if (res.status == 'success') {
            let accountInfo = res.user;
            this._infoUser = res.user;
            // se guarda la informacion del usuario en el localstorage
            this.storage.set('infoUser', accountInfo);
            setTimeout(() => {
              this.getInfoUser();
            }, 300);
          }
          resolve(res);
        },
        (err) => {
          reject(err);
          console.error('error provider infoUser: ', err);
        }
      );
    });
  }

  loginAuthMain(email: any, name_database: any) {
    let info = {
      email: email,
      name_database: name_database,
    };
    return this.apiMainProvider.postFree('validate/login', info);
  }

  getDocumentType(token: any) {
    return new Promise((resolve, reject) => {
      this.api.get('user/getDocumentTypes', token).subscribe(
        (res: any) => {
          if (res.status == 'success') {
            this._documentType = res.documents_types;
          }
          resolve(res);
        },
        (err) => {
          reject(err);
          console.error('error provider getDocumentType: ', err);
        }
      );
    });
  }

  getInfoUser() {
    return new Promise((resolve, reject) => {
      this.storage.get('infoUser').then((infoUser) => {
        if (infoUser) {
          this._infoUser = infoUser;
          resolve(infoUser);
        }
      }, reject);
    });
  }

  updateInfoUser(userInfo: any, password: any, token: any) {
    return new Promise((resolve, reject) => {
      this.api.put('user/update', userInfo, token).subscribe(
        (res: any) => {
          if (res.status == 'success') {
            let accountInfo = res.user;
            // se guarda la informacion del usuario en el localstorage
            this.storage.set('infoUser', accountInfo);
            setTimeout(() => {
              resolve(res);
              this.getInfoUser();
            }, 300);
          }
        },
        (err) => {
          reject(err);
          console.error('error provider updateInfoUser: ', err);
        }
      );
    });
  }

  updateAccountBalance(user_id: any, token) {
    return new Promise((resolve, reject) => {
      this.api
        .put('pos/updateAccountBalance/' + user_id, null, token.access_token)
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          (err) => {
            reject(err);
            console.error('error provider updateAccountBalance: ', err);
          }
        );
    });
  }

  rememberPassword(email: any) {
    let info = {
      email: email,
    };
    return this.apiMainProvider.postFree('recoverPassword', info);
  }

  getlastAddressUser() {
    return new Promise((resolve, reject) => {
      this.storage.get('infoUser').then((infoUser) => {
        let address = null;
        if (infoUser) {
          address = infoUser.addresses.find(
            (address: any) => address.last_used == 1
          );
        }
        resolve(address);
      }, reject);
    });
  }

  updateLastUsedAddress(id: any) {
    this.storage.get('infoUser').then((infoUser) => {
      if (infoUser) {
        for (let i = 0; i < infoUser.addresses.length; i++) {
          if (infoUser.addresses[i].id == id) {
            infoUser.addresses[i].last_used = 1;
          } else {
            infoUser.addresses[i].last_used = 0;
          }
        }
        this._infoUser = infoUser;
        this.storage.set('infoUser', this._infoUser);
      }
    });
  }

  changeCityUser(email: any, password: any) {
    return new Promise((resolve, reject) => {
      this.storage.get('infoHost').then((infoHost) => {
        if (infoHost) {
          this.loginAuthMain(email, infoHost.name_database).subscribe(
            (validate) => {
              if (validate['status'] == 'success') {
                this.auth
                  .getAccessToken(email, password)
                  .then((token) => {
                    if (token['m'] != null) {
                      this.utilsService.presentToast(
                        3000,
                        'warning',
                        'top',
                        token['m']
                      );
                      return;
                    }

                    this.storage.set('token', {
                      access_token: token['access_token'],
                      token_refresh: token['refresh_token'],
                    });
                    this.infoUser(email, token['access_token']).then(
                      (res) => {
                        if (res['status'] == 'success') {
                          // si guarda la sucursal en la informacion del usuario para la logica de productos por sucursal
                          if (infoHost.sucursal_products) {
                            this.storage.get('sucursalSelected').then((_) => {
                              this.openModalSucursalConnection(
                                token['access_token']
                              );
                            });
                          }
                          resolve(res);
                        }
                      },
                      (error) => {
                        console.log('error getInfoUser changeCityUser', error);
                      }
                    );
                  })
                  .catch((error) => {
                    console.log('getAccessToken changeCityUser error: ', error);
                  });
              }
            },
            (error) => {
              console.log('error loginAuthMain: ', error);
              reject(error);
            }
          );
        }
      });
    });
  }

  async openModalSucursalConnection(token: any) {
    const modal = await this.modalCtrl.create({
      component: SucursalConnectionPage,
      componentProps: { buttonBack: false },
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.sucursalProvider.updateSucursalUser(data.id, token);
    }
  }

  registerDiscountShipments(token: any) {
    let seq = this.api.get('user/registerDiscountShipments', token);
    seq.subscribe(
      (res: any) => {
        console.log('res registerDiscountShipments: ', res);
      },
      (err) => {
        console.error('Error provider address getAllDepartment', err);
      }
    );
  }

  updateViewTutorial(token: any) {
    let seq = this.api.get('user/updateViewTutorial', token);
    seq.subscribe(
      (_) => {
        this._infoUser.user_info.is_tutorial_viewed = true;
      },
      (err) => {
        console.error('Error updateViewTutorial', err);
      }
    );
  }

  updateLastSession() {
    return new Promise((resolve, reject) => {
      this.storage.get('token').then((token) => {
        if (token) {
          let seq = this.api.get('user/updateLastSession', token.access_token);
          seq.subscribe(
            (res: any) => {
              resolve(res);
            },
            (err) => {
              reject();
              console.error('Error updateLastSession', err);
            }
          );
        }
      });
    });
  }
}
